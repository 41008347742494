import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {LoadingRocket} from '../atoms/loading'

import {useGraphql} from '../hooks/graphql'
import {IAsset, IAssetPayload, Identifiable, IDynamicElement} from '../Interfaces'

function ImageSelect({element, handleElementChange, setPage, toggle, attr = 'elementAttributes', attr2 = 'src'}: {element: IDynamicElement, handleElementChange: any, setPage:any, toggle: any, attr?: string, attr2?: string}) {
  const {gQuery} = useGraphql()
  const [showImages, setShowImages] = useState<boolean>(false)
  const [assets, setAssets] = useState<IAssetPayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
  })
  const {domain} = useParams<Identifiable>()

  useEffect(() => {
    if (assets.state === 'IDLE') {
      gQuery(
        `
          {
            websiteAssets(domain: "${domain}") {
              name
              path
              url
            }
          }
        `,
        {setResp: setAssets, queryName: null},
      )
    }
  }, [gQuery, domain, assets])

  function toggleImages() {
    setShowImages(!showImages)
  }

  return (
    <div style={{position: 'relative'}}>
      <span className="btn-primary" onClick={() => toggleImages()}>
        {showImages ? 'Hide Images' : 'Show Images'}
      </span>
      {assets.resp ? (
        assets.resp.websiteAssets.map((asset: IAsset) => {
          return (
            <div
              key={asset.url}
              className="w-full flex text-left border-b py-2 align-middle"
            >
              <span className="flex-1">{asset.name}</span>
              {showImages ? (
                <span className="flex-1">
                  <img src={asset.url} alt={asset.name} className="w-64"></img>
                </span>
              ) : null}
              <button
                className="btn-primary flex-2 h-auto"
                onClick={() => {
                  handleElementChange({
                    newValue: asset.url,
                    element: element,
                    setPage: setPage,
                    attr: attr,
                    attr2: attr2
                  })
                  toggle()
                }}
              >
                select
              </button>
            </div>
          )
        })
      ) : (
        <LoadingRocket />
      )}
    </div>
  )
}
export default ImageSelect
