import React from 'react'
import Footer from '../components/footer'
import MainMenu from '../components/main-menu'

function WelcomeScreen() {
  return (
    <>
      <MainMenu />
      <div className="max-w-screen-lg mx-auto my-2 shadow-md m-3 py-12 px-16">
        <h1 className="text-3xl mx-auto mb-6 px-10 text-center">
          Thank you for submitting your website info!
        </h1>
        <p className="mb-6">
          We've received your design and will review it shortly. You can check back in to
          see progress or wait for us to follow up via email.
        </p>
        <b>Your Tasks:</b>
        <ul className='pb-4'>
          <li>Sign up with email ✅</li>
          <li>Submit website details ✅</li>
        </ul>
        <b>Our Tasks:</b>
        <ul>
          <li>Review Design ⭕</li>
          <li>Create Draft ⭕</li>
        </ul>
      </div>
      <Footer />
    </>
  )
}
export default WelcomeScreen
