function Range({start, end}: {start: number; end: number}) {
  const step = start < end ? 1 : -1
  const arr = []
  if (step > 0) {
    for (let i = start; i <= end; i += step) {
      arr.push(i.toString())
    }
  } else {
    for (let i = start; i >= end; i += step) {
      arr.push(i.toString())
    }
  }
  return arr
}

export {Range}
