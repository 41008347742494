import React, {useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'

import {LoadingRocket} from '../../atoms/loading'

import MainMenu from '../../components/main-menu'
import Footer from '../../components/footer'

import ResellerView from './views/reseller'
// import TokensView from './views/tokens'
import AccountDetailsView from '../../views/account_details'
import AuthGroupsView from './views/AuthGroups'
import LeadsView from './views/leads'
import { useGraphql } from '../../hooks/graphql'
import { IApiPayload, IAuthUser } from '../../Interfaces'

function AccountScreen({authUser}: {authUser: IAuthUser}) {
  const {isLoading, user} = useAuth0()

  const {gQuery} = useGraphql()
  const [accountPayload, setAccountPayload] = useState<IApiPayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
  })

  useEffect(() => {
    if (accountPayload.state === 'IDLE') {
      gQuery(
        `
        {
          leads {
            id
            email
            role
          }
          authGroups {
            id
            name
            usersCount
            websitesCount
            authGroupUsers {
              id
              role
              authUser {
                id
                email
              }
            }
          }
        }
      `,
        {setResp: setAccountPayload, queryName: ''},
      )
    }
  }, [gQuery, accountPayload])

  const callbackUrl =
    'https://github.com/login/oauth/authorize' +
    '?client_id=' +
    process.env.REACT_APP_GITHUB_CLIENT_ID +
    '&redirect_uri=' +
    process.env.REACT_APP_GITHUB_REDIRECT_URI

  return isLoading ? (
    <LoadingRocket />
  ) : (
    <>
      <MainMenu />
      {user && accountPayload.resp ? (
        <div className="account-view max-w-screen-lg mx-auto my-2">
          <AccountDetailsView authUser={authUser} />
          {/* <TokensView /> */}
          <AuthGroupsView authGroups={accountPayload.resp.authGroups} leads={accountPayload.resp.leads} />
          {/* <LeadsView leads={accountPayload.resp.leads} authGroups={accountPayload.resp.authGroups} /> */}
          <ResellerView authUser={authUser} />
        </div>
      ) : null}
      <Footer />
    </>
  )
}

export default AccountScreen
