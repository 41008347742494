import React from 'react'

interface PethyImageInterface {
  path?: string
  name?: string
  className?: string
  alt: string
  title?: string
  id?: string
  style?: object
}
interface PethyBackgroundInterface {
  path?: string
  name: string
  style?: object
  children: any
}

function PethyImage({
  alt,
  title,
  style,
  className,
  id,
  name = '',
  path = 'https://host.pethy.app/assets/images/',
}: PethyImageInterface) {
  const src = path + name
  return <img src={src} className={className} title={title} alt={alt} style={style} id={id} />
}
function PethyBackground({
  name,
  style,
  path = 'https://host.pethy.app/assets/images/',
  children,
}: PethyBackgroundInterface) {
  const src = path + name
  return (
    <div
      className="hero-image"
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'middle',
        height: '600px',
        ...style,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '70%',
          left: '50%',
          transform: 'translate(-50%, -70%)',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export {PethyImage, PethyBackground}
