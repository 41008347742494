import React from 'react'
import {LoadingRocket} from './atoms/loading'
import {Link} from 'react-router-dom'
import {PethyImage} from './atoms/image'

import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'

import {useAuth0} from '@auth0/auth0-react'
import {FetchProvider as AuthenticatedFetchContext} from './AuthenticatedFetchContext'
import {FetchProvider as UnauthenticatedFetchProvider} from './FetchContext'

function App() {
  const {user, isLoading, error} = useAuth0()

  function Loading() {
    return (
      <>
        <nav className="p-nav">
          <Link
            to="/"
            className="logo"
            id="nav-logo"
            style={{color: 'black', textDecoration: 'none', padding: 10, opacity: 100}}
          >
            <PethyImage
              name="pethy-logo.png"
              alt="Pethy Logo"
              style={{maxWidth: 150, margin: 5}}
            />
          </Link>
        </nav>
        <LoadingRocket />
      </>
    )
  }
  if (isLoading) return <Loading />
  
  return (
    <>
      <div className="wcw">
        {error ? <div className='bg-red-300 p-1'>Oops... {error.message}</div> : null}
        {user ? (
          <AuthenticatedFetchContext>
            <AuthenticatedApp />
          </AuthenticatedFetchContext>
        ) : (
          <UnauthenticatedFetchProvider>
            <UnauthenticatedApp />
          </UnauthenticatedFetchProvider>
        )}
      </div>
    </>
  )
}

export default App
