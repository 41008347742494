import React, {useEffect, useState} from 'react'

import {LoadingRocket} from '../atoms/loading'
import {useGraphql} from '../hooks/graphql'

import {IPresetPayload, IDynamicElement, IPagePayload, IElement} from '../Interfaces'
import {elementReducer, generateNewIds} from './element-handler'
import produce from 'immer'
import { DeleteIcon } from '../atoms/icons'
import { ContentElement } from './ContentElement'

function addPreset({
  newElement,
  parentElement,
  setPage,
}: {
  newElement: IElement
  parentElement: IElement
  setPage: (object: object) => void
}) {
  setPage(
    produce((draft: IPagePayload) => {
      draft.state = 'modified'
      if (draft.resp) {
        const el: IDynamicElement | undefined = elementReducer(draft.resp, {ids: parentElement.ids})
        if (el && newElement) {
          // Recursive Override
          const updatedElement = generateNewIds(newElement, parentElement)
          // Preserve the sidebar display for re-render
          if (el.childElements) {

            el.childElements = [
              ...el.childElements,
              updatedElement
            ]
          } else {
            el.childElements = [updatedElement]
          }
          draft.selectedElement = el
        }
      }
    }),
  )
}

function PresetSettings({page, element}: {page: IPagePayload; element: IDynamicElement}) {
  return (
    <div style={{position: 'relative'}}>
      <div className="w-full text-left border-b py-2 align-middle">
        <p className="mb-2">
          Presets consist of an element and it's children. By saving a preset this set of
          elements can be easily copied to other parts of the website.
        </p>
        <p className="mb-2">
          The name is a unique identifier, so if the same name is reused it will override
          the previously existing preset of the same name.
        </p>
      </div>
      <h3>Preset Preview</h3>
      <div className="w-full border p-3">
        <ContentElement
          ids={[element.id]}
          element={element}
          key={element.id}
        />
      </div>
    </div>
  )
}
function PresetSelect({
  element,
  setPage,
  toggle,
}: {
  element: IDynamicElement
  setPage: any
  toggle: any
}) {
  const {gQuery, gElementQueryString, gMutation} = useGraphql()
  const [showImages, setShowImages] = useState<boolean>(false)
  const [presets, setPresets] = useState<IPresetPayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
  })

  useEffect(() => {
    if (presets.state === 'IDLE') {
      gQuery(
        `
          {
            presets {
              name
              owner
              element {
                ${gElementQueryString}
              }
            }
          }
        `,
        {setResp: setPresets, queryName: 'presets'},
      )
    }
  }, [gQuery, presets, gElementQueryString])

  function toggleImages() {
    setShowImages(!showImages)
  }
  function destroyPreset(element: IElement) {
    gMutation(
      `
        mutation destroyPreset {
          destroyPreset(
            input: {
              presetName: "${element.name}",
            }
          ) {
            errors
            presets {
              name
              owner
              element {
                ${gElementQueryString}
              }
            }
          }
        }
      `,
      {
        setResp: setPresets,
        initData: presets.resp,
        mutationName: 'destroyPreset',
        attributeName: 'presets',
      },
    )
  }
  return (
    <div className="w-full relative">
      <span className="btn" onClick={() => toggleImages()}>
        {showImages ? 'Hide Images' : 'Show Images'}
      </span>
      {presets.resp ? (
        presets.resp.length < 1 ? (
          <p>No Presets found...</p>
        ) : (
          presets.resp.map(
            (
              {
                name,
                owner,
                element: presetElement,
              }: {
                name: string
                owner: boolean
                element: IElement
              },
              index: number,
            ) => {
              const publicStatus = owner ? 'private' : 'public'
              return (
                <div
                  key={publicStatus + '-' + presetElement.id}
                  className={`${
                    index % 2 == 0 ? '' : 'bg-gray-200'
                  } w-full flex text-left border-b p-2 align-middle`}
                >
                  {showImages ? (
                    <span className="flex-1">
                      <img
                        src={element.elementAttributes?.src}
                        alt={name}
                        className="w-64"
                      ></img>
                    </span>
                  ) : null}
                  <span className="flex-1">{name}</span>
                  <button
                    className="btn flex-2 h-auto"
                    onClick={() => {
                      addPreset({
                        newElement: presetElement,
                        parentElement: element,
                        setPage: setPage,
                      })
                      toggle()
                    }}
                  >
                    Select
                  </button>
                  {owner ? (
                    <button
                      className="flex-2 h-auto"
                      onClick={() => {
                        destroyPreset(presetElement)
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  ) : null}
                </div>
              )
            },
          )
        )
      ) : (
        <LoadingRocket />
      )}
    </div>
  )
}

export {PresetSettings, PresetSelect}
