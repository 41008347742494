import React from 'react'

import MainMenu from '../components/main-menu'
import Footer from '../components/footer'

function NotFoundScreen() {
  return (
    <>
      <MainMenu />
        <h1 className='text-center text-xl'>Contact form submitted.</h1>
        <p className="text-center p-4">Thank you for reaching out, we will be in touch soon. <br /><br /><a href="/" className='btn'>Return to home.</a></p>
      <Footer />
    </>
  )
}

export default NotFoundScreen
