import React from 'react'
import {LoadingRocket} from '../atoms/loading'

function AsyncJsx({
  isLoading,
  isSuccess,
  children,
}: {
  isLoading: boolean
  isSuccess: boolean
  children: any
}) {
  return (
    <>
      {isLoading ? <LoadingRocket /> : null}
      {children}
    </>
  )
  // return isLoading ? (
  //   <div className="d-flex w-100 justify-content-center p-2">
  //     <LoadingRocket />
  //   </div>
  // ) : isSuccess ? (
  //   children
  // ) : null
}
export {AsyncJsx}
