import React, {useEffect, useState} from 'react'

import {useAuth0} from '@auth0/auth0-react'
import {useParams} from 'react-router-dom'
import {Identifiable} from '../../Interfaces'

import MainMenu from '../../components/main-menu'
import Footer from '../../components/footer'
import {useForm} from 'react-hook-form'
import {useGraphql} from '../../hooks/graphql'

import * as ActiveStorage from '@rails/activestorage'

function WebsiteAssetsScreen() {
  // if (process.env) var env = process.env
  const {isLoading, user} = useAuth0()
  const {domain} = useParams<Identifiable>()
  const {register, handleSubmit} = useForm()
  const {gAsyncMutation} = useGraphql()
  const [fields, setFields] = useState<any>(null)

  const endpoint = `${process.env.REACT_APP_PETHY_API_URL}/rails/active_storage/direct_uploads`
  useEffect(() => {
    // console.log('fields', fields)
    const awaitMutation = async function(fields: any) {
      await gAsyncMutation(
        `
          mutation uploadFiles {
            uploadFiles(
              input: {
                domain: "${fields.domain || ''}",
                file: "${fields.file}",
              }
            ) {
              website {
                domain
              }
              errors
            }
          }
        `,
        {
          mutationName: 'uploadFiles',
          attributeName: 'website',
        },
      )
      // history.go(0)
    }
    if (
      (fields?.file && typeof fields?.file === 'string') ||
      fields?.file instanceof String
    ) {
      awaitMutation(fields)
    }
  }, [fields])
  useEffect(() => {
    let isSubscribed = true

    ActiveStorage.start()
    // window.addEventListener('direct-upload:initialize', (event: any) => {
    //   const {target, detail} = event
    //   const {id, file} = detail
    //   target.insertAdjacentHTML(
    //     'beforebegin',
    //     `
    //       <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
    //         <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
    //         <span class="direct-upload__filename">${file.name}</span>
    //       </div>
    //     `,
    //   )
    // })

    // window.addEventListener('direct-upload:start', (event: any) => {
    //   const {id} = event.detail
    //   const element = document.getElementById(`direct-upload-${id}`)
    //   element.classList.remove('direct-upload--pending')
    // })

    // window.addEventListener('direct-upload:progress', (event: any) => {
    //   const {id, progress} = event.detail
    //   const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    //   progressElement.style.width = `${progress}%`
    // })

    // window.addEventListener('direct-upload:error', (event: any) => {
    //   event.preventDefault()
    //   const {id, error} = event.detail
    //   const element = document.getElementById(`direct-upload-${id}`)
    //   element.classList.add('direct-upload--error')
    //   element.setAttribute('title', error)
    // })

    window.addEventListener('direct-upload:end', (event: any) => {
      // const {id, file} = event.detail
      // const element = document.getElementById(`direct-upload-${id}`)
      // element.classList.add('direct-upload--complete')
      const upload: any = document.getElementsByName('file')[0]
      if (isSubscribed) {
        console.log(upload)
        setFields({...fields, file: upload.value})
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [fields])

  return (
    <>
      <MainMenu />

      {!isLoading ? (
        <div className="max-w-screen-lg mx-auto my-2 shadow-md m-3 py-12 px-16">
          <h1 className="text-3xl mx-auto mb-6 px-10 text-center">Assets for {domain}</h1>
          <form>
            <input
              name="file"
              {...register('file')}
              type="file"
              className="input"
              data-direct-upload-url={endpoint}
            />
            <button className="btn">Submit</button>
          </form>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <Footer />
    </>
  )
}
export default WebsiteAssetsScreen
