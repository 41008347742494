import React from 'react'

const ApiErrors = ({
  errorArrays,
}: {
  errorArrays: [{name: string; errors: Array<string>}]
}) => {
  return (
    <div>
      {errorArrays.map(errorArray =>
        errorArray.errors && errorArray.errors.length > 0 ? (
          <div key={errorArray.name} className="application-errors bg-red-300 p-1">
            {errorArray.errors.map((error: string) => {
              return <span key={error}> {error} </span>
            })}
          </div>
        ) : null,
      )}
    </div>
  )
}

function SimpleError({error}: {error: string}) {
  return error ? (
    <div className="application-errors bg-red-300 p-1">
      <span key={error}> {error} </span>
    </div>
  ) : null
}

export default ApiErrors
export {SimpleError}
