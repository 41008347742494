import React from 'react'

function ContactForm() {
  // if (process.env) var env = process.env
  // const params = new URLSearchParams(window.location.search)
  // const [errors] = useState<any>(params)
  // console.log(params)
  // useEffect(() => {
  //   if (errors === false) {
  //     // const newErrors = [] as string[]
  //     // for (const [key, value] of params) {
  //     //   if (key === 'errors') {
  //     //     newErrors.push(value)
  //     //   }
  //     // }
  //     setErrors(params)
  //   }
  // }, [errors])
  return (
    <>
      {/* <div className={`${errors === false ? 'hidden' : ''} bg-red-600 bg-opacity-50 p-4`}>
        {errors && Object.keys(errors).map((key: string) => (
          <div key={key}>{errors[key]}</div>
        ))}
      </div> */}
      <form action={process.env.REACT_APP_CONTACT_FORM_ENDPOINT} method="post" className="w-full">
        <div className="w-full py-2">
          <label className="block" htmlFor="email">
            Your Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="block bg-gray-100 w-full p-2"
          ></input>
        </div>
        <div className="w-full py-2">
          <label className="block" htmlFor="name">
            Your Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className="block bg-gray-100 w-full p-2"
          ></input>
        </div>
        <div className="hidden">
          <input id="email_2" name="email_2" type="text"></input>
          <input
            id="body"
            name="body"
            type="text"
            className="block bg-gray-100 w-full p-2"
          ></input>
          <input type="hidden" name="return_url" value="https://pethy.app/form-submitted"></input>
        </div>
        <div className="w-full py-2">
          <label className="block" htmlFor="content">
            Message
          </label>
          <textarea
            id="content"
            name="content"
            className="block bg-gray-100 w-full p-2"
          ></textarea>
        </div>
        <button type="submit" className="btn">
          Submit
        </button>
      </form>
    </>
  )
}
export default ContactForm
