import React from 'react'
import {useAuth0} from '@auth0/auth0-react'

import {LoadingRocket} from '../../atoms/loading'

import MainMenu from '../../components/main-menu'
import Footer from '../../components/footer'

import AccountDetailsView from '../../views/account_details'
import { IAuthUser } from '../../Interfaces'

function AccountScreen({authUser}: {authUser: IAuthUser}) {
  const {isLoading, user} = useAuth0()

  return isLoading ? (
    <LoadingRocket />
  ) : (
    <>
      <MainMenu />
      {user ? (
        <div className="account-view max-w-screen-lg mx-auto my-2">
          <AccountDetailsView authUser={authUser} />
        </div>
      ) : null}
      <Footer />
    </>
  )
}

export default AccountScreen
