import ContactForm from "./ContactForm"
import { PethyNavigationControls, PethyNavigation } from "./PethyNavigation"
import { PethyCarousel, PethyCarouselControls } from "./PethyCarousel"
import { PethyDynamicList, PethyDynamicListControls } from "./PethyDynamicList"
import { PethyHiddenContent, PethyHiddenContentControls } from "./PethyHiddenContent"
import { PethyToggleContent, PethyToggleContentControls } from "./PethyToggleContent"
import { PethyNavigationHighlights, PethyNavigationHighlightsControls } from "./PethyNavigationHighlights"

const allModules: {[key: string]: any} = {
  'PethyContactForm': {
    name: 'Contact Form',
    image: '',
    module: ContactForm,
    controls: null
  },
  'PethyNavigation': {
    name: 'Navigation',
    image: '',
    module: PethyNavigation,
    controls: PethyNavigationControls
  },
  'PethyCarousel': {
    name: 'Image Carousel',
    image: '',
    module: PethyCarousel,
    controls: PethyCarouselControls
  },
  'PethyDynamicList': {
    name: 'Dynamic List',
    image: '',
    module: PethyDynamicList,
    controls: PethyDynamicListControls
  },
  'PethyHiddenContent': {
    name: 'Hidden Content',
    image: '',
    module: PethyHiddenContent,
    controls: PethyHiddenContentControls
  },
  'PethyToggleContent': {
    name: 'Toggle Content',
    image: '',
    module: PethyToggleContent,
    controls: PethyToggleContentControls
  },
  'PethyNavigationHighlights': {
    name: 'Navigation Highlights',
    image: '',
    module: PethyNavigationHighlights,
    controls: PethyNavigationHighlightsControls
  }
}

export default allModules