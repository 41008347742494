import React, {createContext, useEffect, useState, useCallback} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {GraphQLClient} from 'graphql-request'

const FetchContext = createContext<any>({})
const {Provider} = FetchContext

function FetchProvider({children}: {children: React.ReactNode}) {
  const [accessToken, setAccessToken] = useState<string>('')
  const [graphQLClient, setAuthorizedGraphQLClient] = useState<any>(null)
  const {getAccessTokenSilently} = useAuth0()

  const getAccessToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
    } catch (err) {
      console.log(err);
    }
  }, [getAccessTokenSilently])

  useEffect(() => {
    getAccessToken()
  }, [accessToken])

  useEffect(() => {
    // if (process.env) var env = process.env

    if (accessToken) {
      const endpoint = `${process.env.REACT_APP_PETHY_API_URL}/v1`
      const auth = accessToken ? `Bearer ${accessToken}` : ''
      const client = new GraphQLClient(endpoint, {
        headers: {
          authorization: auth,
        },
      })
      setAuthorizedGraphQLClient(client)
      // const graphQLClient = new GraphQLClient(endpoint)
    }
  }, [accessToken])

  if (graphQLClient !== null) {
    return (
      <Provider
        value={{
          graphQLClient,
        }}
      >
        {children}
      </Provider>
    )
  } else {
    return null
  }
}

export {FetchContext, FetchProvider}
