import React from 'react'
import {useContext} from 'react'
import {ModeContext} from '../ModeContext'

function FormField({
  field,
  element,
  register,
  error,
  setValue,
}: {
  field: any
  element: any
  register: any
  error: any
  setValue: any
}) {
  const {advMode} = useContext(ModeContext)
  const field_type = !advMode && field.advMode ? 'hidden' : field.type

  function DisplayErrors({
    id,
    error,
  }: {
    id: string
    error: {[k: string]: {message: string}}
  }) {
    return error && error[id] ? (
      <div className="invalid-feedback">
        {error[id].message || 'This field is required'}
      </div>
    ) : null
  }
  return field_type === 'textarea' ? (
    <div key={field.id} className="m-1 flex-33 p-1" style={{width: '100%'}}>
      <label htmlFor={field.id} title={field.title}>{field.placeholder}</label>
      <textarea
        style={{width: '100%', height: '100px'}}
        id={field.id}
        name={field.name}
        title={field.title}
        defaultValue={element[field.id]}
        className="bg-gray-100 p-2"
        {...register(field.name, field.registerOpts)}
      />
    </div>
  ) : field_type === 'select' ? (
    <div className={`m-1 text-left ${field_type}`}>
      <label htmlFor={field.id} title={field.title}>{field.placeholder}</label>
      <select
        placeholder={field.placeholder}
        id={field.id}
        name={field.name}
        title={field.title}
        defaultValue={element[field.id]}
        className="select"
        {...register(field.name, field.registerOpts)}
      >
        {field.options.map((option: string) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        })}
      </select>
    </div>
  ) : (
    <div className={`m-1 text-left ${field_type}`}>
      <label htmlFor={field.id} title={field.title}>{field.placeholder}</label>
      <input
        placeholder={field.placeholder}
        id={field.id}
        name={field.name}
        title={field.title}
        defaultValue={element[field.id]}
        type={field_type}
        className={error && error[field.id] ? 'input bg-red-600' : 'input form-field'}
        {...register(field.name, field.registerOpts)}
      ></input>
      <DisplayErrors id={field.id} error={error} />
    </div>
  )
}

export default FormField
