// function Rocket(): null {
//   return null
// }
// export default Rocket
import React, {useEffect, useRef} from 'react'
import {PethyImage} from '../atoms/image'
import gsap from 'gsap'

function RocketCycle() {
  const rocketEl = useRef(null)
  const trailEl = useRef(null)
  const smokeEls = useRef<any>([])
  const smokeObjs = [
    {
      id: 'smoke1',
      degrees: 360,
      styles: {
        transformOrigin: '45% 55%',
        left: 30,
        bottom: -190,
      },
    },
    {
      id: 'smoke2',
      degrees: 360,
      styles: {
        transformOrigin: '50% 40%',
        left: 0,
        bottom: -170,
      },
    },
    {
      id: 'smoke3',
      degrees: 360,
      styles: {
        transformOrigin: '40% 40%',
        left: 80,
        bottom: -170,
      },
    },
    {
      id: 'smoke4',
      degrees: 360,
      styles: {
        transformOrigin: '50% 30%',
        left: 60,
        bottom: -200,
      },
    },
    {
      id: 'smoke5',
      degrees: -360,
      styles: {
        transformOrigin: '50% 30%',
        left: 60,
        bottom: -220,
      },
    },
    {
      id: 'smoke6',
      degrees: -360,
      styles: {
        transformOrigin: '40% 40%',
        left: 120,
        bottom: -200,
      },
    },
    {
      id: 'smoke7',
      degrees: 360,
      styles: {
        transformOrigin: '40% 40%',
        left: 0,
        bottom: -200,
      },
    },
  ]
  const rocketCycle = () => {
    const tl = gsap.timeline()
    tl.to(rocketEl.current, {
      duration: 1.5,
      y: -25,
      yoyoEase: 'power1.out',
      yoyo: true,
      repeat: -1,
    })
    return tl
  }
  const rocketSmoke = (el: any, rotation: number) => {
    const tl = gsap.timeline()
    tl.from(
      el,
      {
        ease: 'none',
        duration: 1,
        rotationZ: rotation,
        repeat: -1,
      },
      '-=1',
    )
    return tl
  }
  useEffect(() => {
    const tl = gsap.timeline()
    tl.add(rocketCycle(), 0)
    smokeEls.current.forEach((el: any) => {
      tl.add(rocketSmoke(el, el.dataset.degrees), 0)
    })
  })
  return (
    <>
      <div id="rocket-animation" ref={rocketEl} style={{position: 'relative'}}>
        <PethyImage
          name="pethy-home-rocket.png"
          alt="Pethy Logo"
          style={{width: '150px', maxWidth: '100%', zIndex: 9, position: 'relative'}}
        />
        <div
          id="trail"
          ref={trailEl}
          style={{
            width: 20,
            margin: '0 auto',
            backgroundColor: 'white',
            height: 100,
            position: 'absolute',
            left: '45%',
            bottom: '-100px',
            zIndex: 1,
          }}
        />
      </div>
      <div id="smoke-animation" className='-left-4 top-0 relative'>
        {smokeObjs.map(obj => (
          <div
            key={obj.id}
            id={obj.id}
            className="smoke"
            data-degrees={obj.degrees}
            ref={el => {
              if (el !== null) {
                smokeEls.current.push(el)
              }
            }}
            style={{
              position: 'absolute',
              borderRadius: 100,
              backgroundColor: 'white',
              width: 120,
              height: 120,
              ...obj.styles,
            }}
          />
        ))}
      </div>
    </>
  )
}

export default RocketCycle
