import React from 'react'
import RocketCycle from '../animations/rocket-cycle'
import {PethyImage} from './image'
import './loading.css'

function LoadingBar() {
  return (
    <div className="progress-wrapper">
      <div className="progress-bar">
        <span className="progress-bar-fill"></span>
      </div>
    </div>
  )
}

// function LoadingRocket() {
//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-10 flex justify-center items-center">
//       <PethyImage
//         name="pethy-home-rocket.png"
//         alt="Pethy Logo"
//         className="w-20 h-25 z-index-10 relative"
//       />
//     </div>
//   )
// }
function LoadingRocket() {
  return (
    <div className="fixed z-index-9 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className='className="w-20 h-25 z-index-10 relative"'>
        <RocketCycle />
      </div>
    </div>
  )
}

export {LoadingBar, LoadingRocket}
