import React from 'react'
import ReactDOM from 'react-dom'
// import './modal.css'

const Modal = ({
  isShowing,
  hide,
  onConfirm,
  children,
  autoHide = true,
  closeText = 'Cancel',
}: {
  isShowing: boolean
  hide: () => void
  onConfirm?: any
  children: any
  autoHide?: boolean
  closeText?: string
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="fixed inset-0 bg-black opacity-50 z-30" />
          <div
            className="wcw fixed inset-0 overflow-x-hidden overflow-y-auto outline-none z-40 flex align-middle justify-center"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="z-50 relative m-2 max-w-screen-lg">
              <div className="text-center bg-white p-8">
                {children}
                {onConfirm ? (
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    aria-label="Confirm"
                    onClick={() => {
                      if (autoHide) {
                        onConfirm()
                        hide()
                      } else {
                        onConfirm()
                      }
                    }}
                  >
                    Confirm
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-gray"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => hide()}
                >
                  {closeText}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body,
      )
    : null

export default Modal
