import React, {useState} from 'react'

import {IDynamicElement} from '../Interfaces'
import allModules from '../modules/all'

function ModuleSelect({
  element,
  handleElementChange,
  setPage,
  toggle,
}: {
  element: IDynamicElement
  handleElementChange: any
  setPage: any
  toggle: any
}) {
  const [showImages, setShowImages] = useState<boolean>(false)


  function toggleImages() {
    setShowImages(!showImages)
  }

  return (
    <div style={{position: 'relative'}}>
      <span className="btn-primary" onClick={() => toggleImages()}>
        {showImages ? 'Hide Images' : 'Show Images'}
      </span>
      {Object.keys(allModules).map((key: string) => {
        return (
          <div
            key={allModules[key].name}
            className="w-full flex text-left border-b py-2 align-middle"
          >
            <span className="flex-1">{allModules[key].name}</span>
            {showImages ? (
              <span className="flex-1">
                <img src={allModules[key].image} alt={allModules[key].name} className="w-64"></img>
              </span>
            ) : null}
            <button
              className="btn-primary flex-2 h-auto"
              onClick={() => {
                handleElementChange({
                  newValue: key,
                  element: element,
                  setPage: setPage,
                  attr: 'moduleName'
                })
                toggle()
              }}
            >
              select
            </button>
          </div>
        )
      })}
    </div>
  )
}
export default ModuleSelect
