import React from 'react'

function TitleSubtitleSection({title, subtitle, bg = "", children}: {title: string; subtitle: string, bg?: string, children: any}) {
  return (
    <div className={`${bg} p-8 text-center`}>
      <h2 className="text-3xl font-bold">{title}</h2>
      <p className="mb-6">{subtitle}</p>
      <div className="flex mx-auto max-w-lg">
        {children}
      </div>
    </div>
  )
}
export default TitleSubtitleSection
