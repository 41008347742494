import {useEffect, useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import UnauthenticatedApp from './UnauthenticatedApp'

import AboutScreen from './screens/about'
import FormSubmittedScreen from './screens/form-submitted'
import NotFoundScreen from './screens/not-found'

import LeadWelcomeScreen from './lead/welcome'
import ProspectThankYouScreen from './prospect/thank-you'

import EditorAccountScreen from './editor/screens/account'
import EditorWebsitesScreen from './editor/screens/websites'

import DeveloperEditorScreen from './developer/screens/editor'
import WebsiteAssetsScreen from './developer/screens/assets'
import WebsiteScreen from './developer/screens/website'
import DeveloperWebsitesScreen from './developer/screens/websites'
import DeveloperAccountScreen from './developer/screens/account'

import {useAuth0} from '@auth0/auth0-react'
import {IApiPayload} from './Interfaces'
import {useGraphql} from './hooks/graphql'

function AuthenticatedApp() {
  const {isLoading, isAuthenticated, user} = useAuth0()
  const {gQuery} = useGraphql()
  const [authUser, setAuthUser] = useState<IApiPayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
  })

  useEffect(() => {
    if (
      !isLoading &&
      isAuthenticated &&
      authUser.state === 'IDLE' &&
      authUser?.resp === null &&
      user?.sub
    ) {
      gQuery(
        `
        {
          authUser {
            id
            email
            reseller
            role
          }
        }
      `,
        {setResp: setAuthUser, queryName: 'authUser'},
      )
    }
  }, [gQuery, authUser, isAuthenticated, isLoading, user])

  switch (authUser?.resp?.role) {
    case 'LEAD':
      return <LeadRoutes />
    case 'PROSPECT':
      return <ProspectRoutes />
    case 'EDITOR':
      return <EditorRoutes />
    case 'DEVELOPER':
      return <DeveloperRoutes />
    default:
      return <UnauthenticatedApp />
  }
  function LeadRoutes() {
    return (
      <Switch>
        <Route path="/about" component={AboutScreen} />
        <Route path="/account" component={LeadWelcomeScreen} />
        <Route path="/websites" component={LeadWelcomeScreen} />
        <Route path="/form-submitted" component={FormSubmittedScreen} />
        <Route path="/" exact component={LeadWelcomeScreen} />
        <Route path="/*" component={NotFoundScreen} />
      </Switch>
    )
  }
  function ProspectRoutes() {
    return (
      <Switch>
        <Route path="/about" component={AboutScreen} />
        <Route
          path="/account"
          component={() => <EditorAccountScreen authUser={authUser?.resp} />}
        />
        <Route path="/form-submitted" component={FormSubmittedScreen} />
        <Route path="/" exact component={ProspectThankYouScreen} />
        {/* <Route path="/*" render={() => <Redirect to="/" />} /> */}
        <Route path="/*" component={NotFoundScreen} />
      </Switch>
    )
  }
  function EditorRoutes() {
    return (
      <Switch>
        <Route path="/assets/:domain" component={WebsiteAssetsScreen} />
        <Route path="/websites/:domain" component={WebsiteScreen} />
        <Route path="/websites" component={EditorWebsitesScreen} />
        <Route
          path="/account"
          component={() => <EditorAccountScreen authUser={authUser?.resp} />}
        />
        <Route path="/about" component={AboutScreen} />
        <Route path="/form-submitted" component={FormSubmittedScreen} />
        <Route path="/" exact component={AboutScreen} />
        <Route path="/*" component={NotFoundScreen} />
      </Switch>
    )
  }
  function DeveloperRoutes() {
    return (
      <Switch>
        <Route path="/editor/:domain/:slug" component={DeveloperEditorScreen} />
        <Route path="/assets/:domain" component={WebsiteAssetsScreen} />
        <Route path="/websites/:domain" component={WebsiteScreen} />
        <Route
          path="/account"
          component={() => <DeveloperAccountScreen authUser={authUser?.resp} />}
        />
        <Route path="/about" component={AboutScreen} />
        <Route path="/websites" component={DeveloperWebsitesScreen} />
        <Route path="/form-submitted" component={FormSubmittedScreen} />
        <Route path="/" exact component={DeveloperWebsitesScreen} />
        <Route path="/*" component={NotFoundScreen} />
      </Switch>
    )
  }
}

export default AuthenticatedApp
