import {useContext} from 'react'
import {gql} from 'graphql-request'
import { FetchContext } from '../AuthenticatedFetchContext'

const useGraphql = () => {
  const {graphQLClient} = useContext(FetchContext)

  const gQuery = async (
    query: string,
    {setResp, queryName}: {setResp: any; queryName: string | null},
  ) => {
    const gqlQuery = gql`
      ${query}
    `
    try {
      setResp({state: 'LOADING', resp: null, errors: []})
      const data = await graphQLClient.request(gqlQuery)
      const apiResp = JSON.parse(JSON.stringify(data, undefined, 2))
      const resp = queryName ? apiResp[queryName] : apiResp

      if (apiResp.errors?.length > 0) {
        setResp({state: 'FINALIZED', resp: resp, errors: apiResp.errors})
      } else {
        setResp({state: 'FINALIZED', resp: resp, errors: []})
      }
    } catch (err) {
      console.error('data:', err)
    }
  }

  const gMutation = async (
    query: string,
    {
      setResp,
      initData,
      mutationName,
      attributeName,
    }: {setResp: any; initData: any; mutationName: string; attributeName: string},
  ) => {
    const gqlQuery = gql`
      ${query}
    `

    try {
      setResp({state: 'LOADING', resp: initData, errors: []})
      const data = await graphQLClient.request(gqlQuery)
      const apiResp = JSON.parse(JSON.stringify(data, undefined, 2))

      if (apiResp[mutationName].errors?.length > 0) {
        setResp({
          state: 'FINALIZED',
          resp: apiResp[mutationName][attributeName],
          errors: apiResp[mutationName].errors,
        })
      } else {
        setResp({
          state: 'FINALIZED',
          resp: apiResp[mutationName][attributeName],
          errors: [],
        })
      }
    } catch (err) {
      console.error('data:', err)
    }
  }

  const gAsyncMutation = async (
    query: string,
    {mutationName, attributeName}: {mutationName: string; attributeName: string},
  ) => {
    const gqlQuery = gql`
      ${query}
    `

    try {
      const data = await graphQLClient.request(gqlQuery)
      const apiResp = JSON.parse(JSON.stringify(data, undefined, 2))

      if (apiResp[mutationName].errors?.length > 0) {
        return {
          state: 'FINALIZED',
          resp: apiResp[mutationName][attributeName],
          errors: apiResp[mutationName].errors,
        }
      } else {
        return {
          state: 'FINALIZED',
          resp: apiResp[mutationName][attributeName],
          errors: [],
        }
      }
    } catch (err) {
      console.error('data:', err)
    }
  }

  const gAppendMutation = async (
    query: string,
    {
      initData,
      setResp,
      appendHandler,
      mutationName,
      attributeName,
    }: {
      initData: any
      setResp: any
      appendHandler: any
      mutationName: string
      attributeName: string
    },
  ) => {
    const gqlQuery = gql`
      ${query}
    `
    try {
      setResp({state: 'LOADING', resp: initData, errors: []})
      const data = await graphQLClient.request(gqlQuery)
      const apiResp = JSON.parse(JSON.stringify(data, undefined, 2))

      if (apiResp[mutationName].errors?.length > 0) {
        setResp({
          state: 'FINALIZED',
          resp: appendHandler(apiResp[mutationName][attributeName]),
          errors: apiResp[mutationName].errors,
        })
      } else {
        setResp({
          state: 'FINALIZED',
          resp: appendHandler(apiResp[mutationName][attributeName]),
          errors: [],
        })
      }
    } catch (err) {
      console.error('data:', err)
    }
  }
  const elementFields = `
    id
    ids
    parentIds
    name
    elementTag
    moduleName
    enum
    isLink
    backgroundImage
    defaultImagePath
    elementAttributes {
      id
      className
    }
    moduleAttributes {
      value
    }
    ... on Nav {
      moduleAttributes {
        logo
        logoAlt
        items {
          title
          href
          desktopClassNames
          mobileClassNames
        }
        
      }
    }
    ... on ToggleContent {
      moduleAttributes {
        trigger
        toggleOnClasses
        toggleOffClasses
        classToToggle
        wrapperClasses
      }
    }
    ... on HiddenContent {
      moduleAttributes {
        hashValue
        hiddenClasses
        visibleClasses
      }
    }
    ... on NavigationHighlights {
      moduleAttributes {
        value
        sections
        navItems
        activeClass
        inactiveClass
      }
    }
    ... on Carousel {
      moduleAttributes {
        autoplay
        items {
          wrapperClasses
          backgroundImage
          backgroundImageHref
          backgroundImageAlt
          content
          contentClasses
        }
      }
    }
    ... on Link {
      elementAttributes {
        id
        className
        title
        alt
        href
      }
    }
    ... on Video {
      elementAttributes {
        id
        className
        title
        src
        type
        alt
      }
    }
    ... on Iframe {
      elementAttributes {
        id
        className
        title
        src
        alt
        allow
      }
    }
    ... on Image {
      elementAttributes {
        id
        className
        title
        src
        alt
      }
    }
    ... on DynamicList {
      moduleAttributes {
        content
        wrapperClasses
        items {
          values
        }
      }
    }
    __typename
    position
  `
  
  const gPageQueryString = `
    id
    title
    slug
    website {
      defaultImagePath
      fontStyles {
        name
        classes
      }
      globalStyles
    }
    childElements {
      ${elementFields}
      childElements {
        ${elementFields}
        childElements {
          ${elementFields}
          childElements {
            ${elementFields}
            childElements {
              ${elementFields}
              childElements {
                ${elementFields}
                childElements {
                  ${elementFields}
                  childElements {
                    ${elementFields}
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const gElementQueryString = `
    ${elementFields}
    childElements {
      ${elementFields}
      childElements {
        ${elementFields}
        childElements {
          ${elementFields}
          childElements {
            ${elementFields}
            childElements {
              ${elementFields}
              childElements {
                ${elementFields}
                childElements {
                  ${elementFields}
                  childElements {
                    ${elementFields}
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  return {
    gQuery,
    gMutation,
    gAppendMutation,
    gAsyncMutation,
    gPageQueryString,
    gElementQueryString
  }
}

export {useGraphql}
