import React, {Fragment, useContext, useState} from 'react'

import {IApiPayload, IElement, IPagePayload} from '../Interfaces'

import {CloseIcon, CodeIcon, QuestionIcon} from '../atoms/icons'
import produce from 'immer'
import useModal from '../hooks/useModal'
import Modal from '../components/modal'
import {ModeContext} from '../ModeContext'
import {DynamicModuleControls, handleElementChange} from './element-handler'
import {PresetSelect, PresetSettings} from './presets'
import {useGraphql} from '../hooks/graphql'
import ApiErrors from '../utils/errors'
import ModuleSelect from './module-select'
import {ImageField} from '../utils/fields'
import {PethyRichTextControls} from '../modules/PethyRichText'

function Sidebar({children, setPage}: {children: any; setPage: any}) {
  // const sidebarRef = useRef(null)
  // useEffect(() => {
  //   sidebarRef.current.style.right = '110%'
  //   sidebarRef.current.style.left = '-10%'

  //   setTimeout(() => {
  //     sidebarRef.current.style.transition = 'right 0.5s linear';
  //     sidebarRef.current.style.left = '0%'
  //     sidebarRef.current.style.right = '75%'
  //   }, 200)
  // }, [children])

  return (
    <>
      <div className="animated-sidebar fixed top-0 right-0 overflow-auto h-screen flex-2 bg-white border border-black p-4 z-50">
        <button
          onClick={() =>
            setPage(
              produce((draft: IPagePayload) => {
                draft.selectedElement = null
              }),
            )
          }
          className="absolute right-3 z-51"
        >
          <CloseIcon />
        </button>
        {children}
      </div>
    </>
  )
}

function SelectionItem({
  element,
  selectedEl,
  setPage,
}: {
  element: IElement
  selectedEl: IElement
  setPage: any
}) {
  return (
    <>
      <span
        className={`${selectedEl.id === element.id ? 'bg-gray-200' : ''} cursor-pointer`}
        onClick={e => {
          e.stopPropagation()
          setPage(
            produce((draft: IPagePayload) => {
              draft.selectedElement = element
            }),
          )
        }}
      >
        {element.name ? element.name : element.id}
      </span>
      {element.childElements ? (
        <div className="pl-2">
          {element.childElements.map((childEl: IElement) => (
            <Fragment key={childEl.id}>
              -{' '}
              <SelectionItem
                element={childEl}
                selectedEl={selectedEl}
                setPage={setPage}
              />
            </Fragment>
          ))}
        </div>
      ) : null}
    </>
  )
}

function ElementSelection({
  element,
  setPage,
  page,
}: {
  element: IElement
  setPage: any
  page: IPagePayload
}) {
  return (
    <>
      {page.resp.childElements
        ? page.resp.childElements.map(el => (
            <div key={el.id}>
              <SelectionItem
                element={el}
                selectedEl={page.selectedElement}
                setPage={setPage}
              />
            </div>
          ))
        : null}
    </>
  )
}

function ElementFields({
  element,
  setPage,
  page,
}: {
  element: IElement
  setPage: any
  page: IPagePayload
}) {
  const {isShowing: savePresetShowing, toggle: toggleSavePreset} = useModal()
  // const {isShowing: imageSelectShowing, toggle: toggleImageSelect} = useModal()
  const {isShowing: moduleSelectShowing, toggle: toggleModuleSelect} = useModal()
  const {isShowing: elementTypeInfoShowing, toggle: toggleElementTypeInfo} = useModal()
  const {isShowing: isLinkInfoShowing, toggle: toggleIsLinkInfo} = useModal()
  const {isShowing: selectPresetShowing, toggle: toggleSelectPreset} = useModal()
  const [presetState, setPresetState] = useState<IApiPayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
  })

  const {advMode} = useContext(ModeContext)
  const {gMutation, gElementQueryString} = useGraphql()

  function savePreset(element: IElement) {
    if (page.resp) {
      gMutation(
        `
          mutation savePreset {
            savePreset(
              input: {
                pageId: "${page.resp.id}",
                element: ${JSON.stringify(JSON.stringify(element))},
              }
            ) {
              errors
              element {
                ${gElementQueryString}
              }
            }
          }
        `,
        {
          setResp: setPresetState,
          initData: element,
          mutationName: 'savePreset',
          attributeName: 'presetElement',
        },
      )
    }
  }

  return (
    <>
      {advMode ? (
        <>
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={element.name}
            onChange={e =>
              handleElementChange({
                newValue: e.target.value,
                element: element,
                setPage: setPage,
                attr: 'name',
              })
            }
            className="max-w-full input"
          />
          <label htmlFor="elementId">Element ID</label>
          <input
            id="elementId"
            type="text"
            className="max-w-full input"
            value={element.elementAttributes?.id || ''}
            onChange={e =>
              handleElementChange({
                newValue: e.target.value,
                element: element,
                setPage: setPage,
                attr: 'elementAttributes',
                attr2: 'id',
              })
            }
          />
          <label htmlFor="className">Element Classes</label>
          <input
            id="className"
            type="text"
            className="max-w-full input"
            value={element.elementAttributes?.className || ''}
            onChange={e =>
              handleElementChange({
                newValue: e.target.value,
                element: element,
                setPage: setPage,
                attr: 'elementAttributes',
                attr2: 'className',
              })
            }
          />
          <label htmlFor="enum">Element Type</label>
          <span onClick={() => toggleElementTypeInfo()} className="pl-2">
            <QuestionIcon />
          </span>
          <Modal
            isShowing={elementTypeInfoShowing}
            hide={toggleElementTypeInfo}
            closeText="Close"
          >
            <p className="mt-4 text-left text-xl">
              <b>Inline</b>
            </p>
            <p className="text-left">The element will only display text or an image.</p>
            <p className="mt-4 text-left text-xl">
              <b>Container</b>
            </p>
            <p className="text-left">The element can contain text and child elements</p>
            <p className="mt-4 text-left text-xl">
              <b>Module</b>
            </p>
            <p className="text-left">
              The element can be set to a module which provides specialized functionaliy
            </p>
          </Modal>
          <select
            id="enum"
            className="select"
            value={element.enum || 'BLOCK'}
            onChange={e =>
              handleElementChange({
                newValue: e.target.value,
                element: element,
                setPage: setPage,
                attr: 'enum',
              })
            }
          >
            <option value="INLINE">Inline</option>
            <option value="BLOCK">Container</option>
            <option value="MODULE">Module</option>
          </select>

          {element.enum === 'MODULE' ? (
            <>
              <label htmlFor="moduleName">Module Type</label>
              <span onClick={() => toggleModuleSelect()} className="pl-2">
                <CodeIcon />
              </span>
              <Modal isShowing={moduleSelectShowing} hide={toggleModuleSelect}>
                <ModuleSelect
                  element={element}
                  setPage={setPage}
                  handleElementChange={handleElementChange}
                  toggle={toggleModuleSelect}
                />
              </Modal>
              <input
                id="moduleName"
                type="text"
                className="max-w-full input"
                value={element.moduleName || ''}
                onChange={e => {
                  handleElementChange({
                    newValue: e.target.value,
                    element: element,
                    setPage: setPage,
                    attr: 'moduleName',
                  })
                }}
              />
            </>
          ) : null}
          <>
            <label htmlFor="className">Element Tag</label>
            <select
              id="element-tag"
              className="select"
              value={element.elementTag || 'NONE'}
              onChange={e =>
                handleElementChange({
                  newValue: e.target.value,
                  element: element,
                  setPage: setPage,
                  attr: 'elementTag',
                })
              }
            >
              <option value="NONE">None Selected (Disabled)</option>
              <option value="TEXT">Div</option>
              <option value="PARAGRAPH">Paragraph</option>
              <option value="SPAN">Span</option>
              <option value="IFRAME">Iframe</option>
              <option value="VIDEO">Video</option>
              <option value="BUTTON">Button</option>
              <option value="H1">H1</option>
              <option value="H2">H2</option>
              <option value="H3">H3</option>
              <option value="H4">H4</option>
              <option value="H5">H5</option>
              <option value="H6">H6</option>
              <option value="UL">Unordered List</option>
              <option value="OL">Ordered List</option>
              <option value="LI">List Item</option>
              <option value="LINK">Link</option>
              <option value="MAIN">Main</option>
              <option value="HEADER">Header</option>
              <option value="FOOTER">Footer</option>
              <option value="SECTION">Section</option>
              <option value="ARTICLE">Article</option>
              <option value="NAV">Nav</option>
              <option value="ASIDE">Aside</option>
              <option value="QUOTE">Quote</option>
              <option value="IMAGE">Image</option>
            </select>
          </>
          <PethyRichTextControls
            element={element}
            handleElementChange={handleElementChange}
            setPage={setPage}
          />
          {element.elementTag === 'IFRAME' ? (
            <>
              <label htmlFor="href">IFRAME ALLOW</label>
              <input
                id="href"
                type="text"
                className="max-w-full input"
                value={element.elementAttributes?.allow || ''}
                onChange={e => {
                  handleElementChange({
                    newValue: e.target.value,
                    element: element,
                    setPage: setPage,
                    attr: 'elementAttributes',
                    attr2: 'allow',
                  })
                }}
              />
            </>
          ) : null}
          {['IFRAME', 'IMAGE', 'VIDEO'].includes(element.elementTag) ? (
            <>
              <label htmlFor="href">Src</label>
              <input
                id="href"
                type="text"
                className="max-w-full input"
                value={element.elementAttributes?.src || ''}
                onChange={e => {
                  handleElementChange({
                    newValue: e.target.value,
                    element: element,
                    setPage: setPage,
                    attr: 'elementAttributes',
                    attr2: 'src',
                  })
                }}
              />
            </>
          ) : null}
          <label htmlFor="className">Background Image</label>
          <input
            id="className"
            type="text"
            className="max-w-full input"
            value={element.backgroundImage || ''}
            onChange={e =>
              handleElementChange({
                newValue: e.target.value,
                element: element,
                setPage: setPage,
                attr: 'backgroundImage',
              })
            }
          />
          {element.elementTag === 'LINK' ? (
            <>
              <label htmlFor="href">Link Url</label>
              <input
                id="href"
                type="text"
                className="max-w-full input"
                value={element.elementAttributes?.href || ''}
                onChange={e => {
                  handleElementChange({
                    newValue: e.target.value,
                    element: element,
                    setPage: setPage,
                    attr: 'elementAttributes',
                    attr2: 'href',
                  })
                }}
              />
            </>
          ) : null}
        </>
      ) : null}
      {element.enum === 'MODULE' ? (
        <>
          <br />
          <hr />
          <p className="font-bold">{element.moduleName}</p>
          <hr />
          <DynamicModuleControls
            moduleName={element.moduleName}
            element={element}
            setPage={setPage}
            handleElementChange={handleElementChange}
          />
        </>
      ) : null}
      {advMode ? (
        <div className="mt-10 mb-2 border-b border-t">
          <button onClick={() => toggleSelectPreset()} className="pl-2 btn">
            Add Preset Element
          </button>
          <button onClick={() => savePreset(element)} className="pl-2 btn">
            Save as Preset
          </button>
          <Modal isShowing={savePresetShowing} hide={toggleSavePreset} closeText="Close">
            <PresetSettings element={element} page={page} />
          </Modal>
          <Modal
            isShowing={selectPresetShowing}
            hide={toggleSelectPreset}
            closeText="Close"
          >
            <PresetSelect
              element={element}
              setPage={setPage}
              toggle={toggleSelectPreset}
            />
          </Modal>
          <span onClick={() => toggleSavePreset()} className="pl-2">
            <QuestionIcon />
          </span>
          {presetState.state === 'FINALIZED' && presetState.errors.length === 0 ? (
            <div className="bg-lime-300 p-1">Preset Saved</div>
          ) : null}
          <ApiErrors errorArrays={[{name: 'PresetErrors', errors: presetState.errors}]} />
        </div>
      ) : null}
    </>
  )
}

export {Sidebar, ElementFields, ElementSelection}
