import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import About from './screens/about'
import NotFoundScreen from './screens/not-found'

function UnauthenticatedApp() {
  // useEffect(() => {
  //   console.log('unauthenticated')
  //   return undefined
  // }, [])

  return <UnauthenticatedRoutes />
}

function UnauthenticatedRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={About} />
      <Route path="/*" component={NotFoundScreen} />
    </Switch>
  )
}

export default UnauthenticatedApp
