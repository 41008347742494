import React, {createContext, useState} from 'react'
import { AdvIcon } from './atoms/icons'

const ModeContext = createContext<any>({})
const {Provider} = ModeContext

function ModeProvider({children}: {children: React.ReactNode}) {
  // const [mode, setMode] = useState<{mode: {advMode: boolean}}>(JSON?.parse(localStorage.getItem('mode')) || 'false')
  const [advMode, setAdvModeState] = useState<boolean>(JSON?.parse(localStorage.getItem('advMode')) || false)
  function handleSetMode(bool: boolean) {
    const json = JSON.stringify(bool)
    localStorage.setItem('advMode', json)
    setAdvModeState(bool)
  }
  function setAdvMode(bool: boolean) {
    localStorage.setItem('advMode', JSON.stringify(bool))
    // mode['mode']['advMode'] = bool
    handleSetMode(bool)
  }
  
  function AdvModeToggle() {
    return <span onClick={() => setAdvMode(!advMode)}><AdvIcon className={advMode ? 'text-pethyGreen' : 'text-gray-300'} /></span>
  }
  
  return (
    <Provider
    value={{
      setAdvMode,
      advMode,
      AdvModeToggle
    }}
    >
      {children}
    </Provider>
  )
}
export {ModeContext, ModeProvider}
