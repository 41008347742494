import React, {useCallback, useEffect} from 'react'

import {v5 as uuidv5, v1 as uuidv1} from 'uuid'
import produce from 'immer'

import {IDynamicElement, IElement, IPagePayload} from '../../Interfaces'
import {pethyClasses} from '../../utils/css'
import {
  DynamicElement,
  DynamicModule,
  elementReducer,
  generateNewIds,
} from '../../components/element-handler'
import DefaultImageHandler from '../../components/DefaultImageHandler'

function LayoutElement({
  ids,
  element,
  page,
  setPage,
}: {
  ids: Array<string>
  element: IElement
  page: IPagePayload
  setPage: any
}) {
  const isSelected = JSON.stringify(page.selectedElement?.ids) === JSON.stringify(element.ids)
  function findWithAttr(array: Array<IDynamicElement>, attr: string, value: string) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i
      }
    }
    return -1
  }

  function moveElement(arr: Array<IDynamicElement>, fromIndex: number, toIndex: number) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
  }

  const keyUpHandler = useCallback((e: any) => {
    if (e.altKey) {
      switch (e.key) {
        case 'PageUp':
          // Select parent element
          setPage(
            produce((draft: IPagePayload) => {
              const parentElementIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {
                  ids: parentElementIds,
                })
                draft.selectedElement = parentElement ? parentElement : null
              }
            }),
          )
          break
        case 'PageDown':
          // Select first child element
          setPage(
            produce((draft: IPagePayload) => {
              if (draft.resp) {
                draft.selectedElement = element.childElements[0]
              }
            }),
          )
          break
        case 'z':
          // Selext previous element
          setPage(
            produce((draft: IPagePayload) => {
              const parentElementIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {
                  ids: parentElementIds,
                })
                const el = parentElement ? parentElement : draft.resp
                const currentElementIndex = el.childElements.findIndex((ce) => ce.id===element.id);
                const previousElementIndex = currentElementIndex === 0 ? el.childElements.length - 1 : currentElementIndex - 1
                const previousElement = el.childElements[previousElementIndex]
                draft.selectedElement = previousElement
              }
            }),
          )
          break
        case 'x':
          // Selext next element
          setPage(
            produce((draft: IPagePayload) => {
              const parentElementIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {
                  ids: parentElementIds,
                })
                const el = parentElement ? parentElement : draft.resp
                const currentElementIndex = el.childElements.findIndex((ce) => ce.id===element.id);
                const nextElementIndex = currentElementIndex === el.childElements.length - 1 ? 0 : currentElementIndex + 1
                const nextElement = el.childElements[nextElementIndex]
                draft.selectedElement = nextElement
              }
            }),
          )
          break
        case 'ArrowUp':
          // Move element Backward
          setPage(
            produce((draft: IPagePayload) => {
              draft.state = 'modified'
              const parentElementIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {
                  ids: parentElementIds,
                })
                const el = parentElement ? parentElement : draft.resp
                const currentElementIndex = el.childElements.findIndex((ce) => ce.id===element.id);
                const previousElementIndex = currentElementIndex === 0 ? el.childElements.length - 1 : currentElementIndex - 1
                moveElement(el.childElements, currentElementIndex, previousElementIndex)
              }
            }),
          )
          break
        case 'ArrowDown':
          // Move element Forward
          setPage(
            produce((draft: IPagePayload) => {
              draft.state = 'modified'
              const parentElementIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {
                  ids: parentElementIds,
                })
                const el = parentElement ? parentElement : draft.resp
                const currentElementIndex = el.childElements.findIndex((ce) => ce.id===element.id);
                const nextElementIndex = currentElementIndex === el.childElements.length - 1 ? 0 : currentElementIndex + 1
                moveElement(el.childElements, currentElementIndex, nextElementIndex)
              }
            }),
          )
          break
        case 'Backspace':
          // Move element up in element hierarchy 
          setPage(
            produce((draft: IPagePayload) => {
              draft.state = 'modified'
              const parentElementIds = ids.filter(id => id !== element.id)
              const parentElement = elementReducer(draft.resp, {
                ids: parentElementIds,
              })
              const currentElement = elementReducer(draft.resp, {
                ids: ids,
              })
              if (currentElement && parentElement) {
                const neededIds = parentElementIds.filter(id => id !== parentElement.id)
                const grandParentElement = elementReducer(draft.resp, {ids: neededIds})
                if (grandParentElement && grandParentElement.ids) {
                  const updatedElement = generateNewIds(
                    currentElement,
                    grandParentElement,
                  )
                  parentElement.childElements = parentElement.childElements.filter(
                    el => el.id !== currentElement.id,
                  )
                  if (draft.selectedElement?.id === parentElement.id) {
                    draft.selectedElement = null
                  }
                  grandParentElement.childElements = [
                    ...grandParentElement.childElements,
                    updatedElement,
                  ]
                }
              }
            }),
          )
          break
        case '*':
          // Add sibling element
          setPage(
            produce((draft: IPagePayload) => {
              draft.state = 'modified'
              const parentElementIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {
                  ids: parentElementIds,
                })
                const el = parentElement
                  ? parentElement
                  : draft.resp
                  ? draft.resp
                  : ({} as IElement)

                const newId = uuidv5('mario-rpg', uuidv1())
                el.childElements = [
                  ...el.childElements,
                  {
                    id: newId,
                    ids: [...parentElementIds, newId],
                    parentIds: parentElementIds,
                    name: '',
                    backgroundImage: '',
                    defaultImagePath: draft.resp?.website?.defaultImagePath || '',
                    enum: 'BLOCK',
                    isLink: false,
                    position: el.childElements.length,
                    elementAttributes: {},
                    moduleAttributes: {},
                    childElements: [],
                    elementTag: 'TEXT',
                    moduleName: '',
                  },
                ]
              }
            }),
          )
          break
        case '+':
          // Add child element
          setPage(
            produce((draft: IPagePayload) => {
              const parentElementIds = ids.filter(id => id !== element.id)
              draft.state = 'modified'
              if (draft.resp) {
                const el = elementReducer(draft.resp, {
                  ids: ids,
                })
                if (el) {
                  const elements = el.childElements
                    ? el.childElements
                    : ([] as IElement[])
                  const newId = uuidv5('mario-rpg', uuidv1())
                  el.childElements = [
                    ...elements,
                    {
                      id: newId,
                      ids: [...ids, newId],
                      parentIds: parentElementIds,
                      name: '',
                      backgroundImage: '',
                      defaultImagePath: draft.resp?.website?.defaultImagePath || '',
                      enum: 'BLOCK',
                      isLink: false,
                      position: elements.length,
                      elementAttributes: {},
                      moduleAttributes: {},
                      childElements: [],
                      elementTag: 'TEXT',
                      moduleName: '',
                    },
                  ]
                }
              }
            }),
          )

          break
        case 'Delete':
          setPage(
            produce((draft: IPagePayload) => {
              draft.state = 'modified'
              const neededIds = ids.filter(id => id !== element.id)
              if (draft.resp) {
                const parentElement = elementReducer(draft.resp, {ids: neededIds})
                const el = parentElement ? parentElement : draft.resp
                el.childElements = el.childElements.filter(el => el.id !== element.id)
                if (draft.selectedElement?.id === el.id) {
                  draft.selectedElement = null
                }
              }
            }),
          )

          break
      }
    }
  }, [])

  useEffect(() => {
    if (isSelected) {
      window.addEventListener('keyup', keyUpHandler)
    }
    return () => {
      if (isSelected) {
        window.removeEventListener('keyup', keyUpHandler)
      }
    }
  }, [keyUpHandler, isSelected])

  return ids.length < 8 ? (
    <DynamicElement
      element={element}
      id={element.elementAttributes?.id || ''}
      dataPethyModuleId={element.id}
      className={`
      ${
        element.enum === 'INLINE'
          ? 'border border-black'
          : pethyClasses.elementEditorDefaults
      }
      ${element.elementAttributes?.className || pethyClasses.elementDefaults}
        ${isSelected ? 'border-dashed' : ''}
      `}
      action={(e: any) => {
        if (element.elementAttributes?.href?.indexOf('#') !== 0) {
          e.preventDefault()
        }
        e.stopPropagation()
        setPage(
          produce((draft: IPagePayload) => {
            draft.selectedElement = element
          }),
        )
      }}
      style={{
        backgroundImage: element.backgroundImage
          ? `url("${DefaultImageHandler(
              element?.backgroundImage || '',
              element?.defaultImagePath || '',
            )}")`
          : null,
      }}
    >
      {element.enum === 'MODULE' ? (
        element.moduleName ? (
          <DynamicModule element={element}>
            {element.childElements?.map(sub_el => (
              <LayoutElement
                ids={[...ids, sub_el.id]}
                element={sub_el}
                key={sub_el.id}
                page={page}
                setPage={setPage}
              />
            ))}
          </DynamicModule>
        ) : null
      ) : (
        element.childElements?.map(sub_el => (
          <LayoutElement
            ids={[...ids, sub_el.id]}
            element={sub_el}
            key={sub_el.id}
            page={page}
            setPage={setPage}
          />
        ))
      )}
    </DynamicElement>
  ) : null
}

export {LayoutElement}
