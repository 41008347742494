import React from 'react'

function IconColumns({children}: {children: any}) {
  return (
    <div className="flex w-full my-4">
      <div className="flex flex-none w-full">
        {children.map((child: any, index: number) => (
          <div className="flex-1 p-4" key={index}>
            {child.props.children}
          </div>
        ))}
      </div>
    </div>
  )
}
export default IconColumns
