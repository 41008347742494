import React, {createContext, useEffect, useState} from 'react'
import {useGraphql} from './hooks/graphql'
import {IApiPayload} from './Interfaces'

const UserContext = createContext<any>({})
const {Provider} = UserContext

function UserProvider({children}: {children: React.ReactNode}) {
  const {gQuery} = useGraphql()
  const [authUser, setAuthUser] = useState<IApiPayload>(null)

  useEffect(() => {
    if (authUser === null) {
      gQuery(
        `
        {
          authUser {
            id
            email
            reseller
            role
          }
        }
      `,
        {setResp: setAuthUser, queryName: 'authUser'},
      )
    }
  }, [gQuery, authUser])

  return (
    <Provider
      value={{
        authUser: authUser?.resp,
      }}
    >
      {children}
    </Provider>
  )
}
export {UserContext, UserProvider}
