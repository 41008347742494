import {IValueModule} from '../Interfaces'
import DOMPurify from 'dompurify'
import {marked} from 'marked'

function PethyRichTextControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: IValueModule
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  return (
    <>
      <label htmlFor="text" className="block w-full">
        Value
      </label>
      {element.elementTag === 'HTML' ? (
        <textarea
          id={'text'}
          className="block w-full input border p-2"
          value={element.moduleAttributes?.value || ''}
          onChange={event => {
            event.persist()
            handleElementChange({
              newValue: event.target.value,
              element: element,
              setPage: setPage,
              attr: 'moduleAttributes',
              attr2: 'value',
            })
          }}
        />
      ) : (
        <input
          id={'text'}
          className="block w-full input border p-2"
          value={element.moduleAttributes?.value || ''}
          onChange={event => {
            event.persist()
            handleElementChange({
              newValue: event.target.value,
              element: element,
              setPage: setPage,
              attr: 'moduleAttributes',
              attr2: 'value',
            })
          }}
        />
      )}
    </>
  )
}

function PethyRichText({moduleElement}: {moduleElement: IValueModule}) {
  return (
    <div
      className="w-full inline-block"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          marked.parseInline(moduleElement.moduleAttributes?.value) || '',
          {
            USE_PROFILES: {html: true},
          },
        ),
      }}
    ></div>
  )
}

export {PethyRichText, PethyRichTextControls}
