import React from 'react'

import {IDynamicElement, IPagePayload} from '../Interfaces'
import {
  DynamicElement,
  DynamicModule,
} from './element-handler'
import {pethyClasses} from '../utils/css'

import ReactDOMServer from 'react-dom/server'
import DefaultImageHandler from './DefaultImageHandler'

function ContentElement({
  ids,
  element,
}: {
  ids: Array<string>
  element: IDynamicElement
}) {
  return ids.length < 8 ? (
    <>
      <DynamicElement
        id={element.elementAttributes?.id || ''}
        dataPethyModuleId={element.id}
        className={element.elementAttributes?.className || pethyClasses.elementDefaults}
        element={element}
        style={{
          backgroundImage: element.backgroundImage
            ? `url("${DefaultImageHandler(
                element?.backgroundImage || '',
                element?.defaultImagePath || '',
              )}")`
            : null,
        }}
      >
        {element.enum === 'MODULE' ? (
          element.moduleName ? (
            <DynamicModule element={element}>
              {element.childElements?.map(sub_el => (
                <ContentElement
                  ids={[...ids, sub_el.id]}
                  element={sub_el}
                  key={sub_el.id}
                />
              ))}
            </DynamicModule>
          ) : null
        ) : (
          element.childElements?.map(sub_el => (
            <ContentElement
              ids={[...ids, sub_el.id]}
              element={sub_el}
              key={sub_el.id}
            />
          ))
        )}
      </DynamicElement>
    </>
  ) : null
}

function StaticHtmlView(page: IPagePayload) {
  return ReactDOMServer.renderToString(
    <>
      {page.resp &&
        page.resp.childElements.map(element => (
          <ContentElement
            ids={[element.id]}
            element={element}
            key={element.id}
          />
        ))}
    </>,
  )
}

export {StaticHtmlView, ContentElement}
