import React, {useState} from 'react'

const CopyText = ({children, textId}: {children: any; textId: string}) => {
  const [copied, setCopied] = useState(false)
  function copyTheText() {
    const copyText = document.getElementById(textId)
    navigator.clipboard.writeText(copyText.innerText)
  }
  return (
    <div>
      <div id={textId}>
        {children}
      </div>
      <button
        className='btn'
        onClick={() => {
          setCopied(true)
          copyTheText()
        }}
      >
        {copied ? 'Copied' : 'Copy'} to Clipboard
      </button>
    </div>
  )
}

export default CopyText
