import DOMPurify from "dompurify"

function GlobalElements({stylesheet}: {stylesheet?: string}) {
  return stylesheet ? (
    <style
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(stylesheet || '', {
          USE_PROFILES: {html: true},
        }),
      }}
    />
  ) : null
}
export default GlobalElements
