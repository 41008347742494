import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Footer from '../../components/footer'
import MainMenu from '../../components/main-menu'
import { UserContext } from '../../UserContext'

function WebsitesScreen() {
  const {authUser} = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    if (!authUser) {
      history.push("/")
    }
  }, [authUser])
  return (
    <>
      <MainMenu />
      {/* <ApiErrors errorArrays={[{name: 'websites', errors: websites.errors}]} /> */}
      <div className="max-w-screen-lg mx-auto my-2 shadow-md m-3 py-6 px-8">
        <p>Contact Developer to get your website setup.</p>
      </div>
      <Footer />
    </>
  )
}
export default WebsitesScreen
