import React from 'react'
// import ReactDOM from 'react-dom'
import {createRoot} from 'react-dom/client'
import './index.css'

// import '@fortawesome/fontawesome-free/css/all.min.css'
// import '@fortawesome/fontawesome-free/js/all.min.js'
import App from './App'
import AppProviders from './AppProviders'

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
)

// ReactDOM.render(
//   <React.StrictMode>
//     <AppProviders>
//       <App />
//     </AppProviders>
//   </React.StrictMode>,
//   document.getElementById('root'),
// )
