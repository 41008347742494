import React, {useEffect} from 'react'
import {IHiddenContentModule} from '../Interfaces'

function PethyHiddenContentControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: IHiddenContentModule
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  return (
    <>
      <label htmlFor="text" className="block w-full">
        URL Hash Value
      </label>
      <input
        id={'text'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.hashValue || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'hashValue',
          })
        }}
      />
      <label htmlFor="hidden" className="block w-full">
        Hidden Classes
      </label>
      <input
        id={'hidden'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.hiddenClasses || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value.replace(/ +/g, ' '),
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'hiddenClasses',
          })
        }}
      />
      <label htmlFor="visible" className="block w-full">
        Visible Classes
      </label>
      <input
        id={'visible'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.visibleClasses || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value.replace(/ +/g, ' '),
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'visibleClasses',
          })
        }}
      />
    </>
  )
}

function PethyHiddenContent({
  moduleElement,
  children,
}: {
  moduleElement: IHiddenContentModule
  children?: any
}) {
  function hiddenContentHandler() {
    const el = document.querySelector(`[data-pethy-module-id="${moduleElement.id}"]`)
    if (el) {
      const hiddenClasses = moduleElement.moduleAttributes.hiddenClasses
        ? moduleElement.moduleAttributes.hiddenClasses
        : 'hidden'
      const isInactive = hiddenClasses.split(' ').every(function(c) {
        return el.classList.contains(c)
      })
      if (`#${moduleElement.moduleAttributes.hashValue}` === location.hash) {
        if (
          isInactive &&
          moduleElement.moduleAttributes?.visibleClasses &&
          moduleElement.moduleAttributes?.hiddenClasses
        ) {
          el.classList.add(...moduleElement.moduleAttributes?.visibleClasses?.split(' '))
          el.classList.remove(
            ...moduleElement.moduleAttributes?.hiddenClasses?.split(' '),
          )
        }
      } else if (
        moduleElement.moduleAttributes?.visibleClasses &&
        moduleElement.moduleAttributes?.hiddenClasses
      ) {
        if (!el.classList.contains(moduleElement.moduleAttributes?.hiddenClasses)) {
          el.classList.add(...moduleElement.moduleAttributes?.hiddenClasses?.split(' '))
          el.classList.remove(
            ...moduleElement.moduleAttributes?.visibleClasses?.split(' '),
          )
        }
      }
    }
  }
  useEffect(() => {
    hiddenContentHandler()
    window.addEventListener('hashchange', hiddenContentHandler)
    return () => {
      window.removeEventListener('hashchange', hiddenContentHandler)
    }
  }, [hiddenContentHandler])
  return <>{children ? children : null}</>
}
export {PethyHiddenContent, PethyHiddenContentControls}
