import {ICarouselModuleItem, ICarouselModule, IPagePayload, IPage} from '../Interfaces'
import {elementReducer} from '../components/element-handler'
import produce from 'immer'
import {AddIcon, ArrowLeftIcon, ArrowRightIcon, DeleteIcon} from '../atoms/icons'
import React, {useEffect, useState} from 'react'
import DynamicItemControl from './DynamicItemControl'
import DOMPurify from 'dompurify'
import DefaultImageHandler from '../components/DefaultImageHandler'

function PethyCarouselControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: ICarouselModule
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  return (
    <>
      <label htmlFor="text" className="block w-full">
        Autoplay
      </label>
      <select
        id="autoplay"
        className="select"
        value={element.moduleAttributes?.autoplay || 'false'}
        onChange={e =>
          handleElementChange({
            newValue: e.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'autoplay',
          })
        }
      >
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
      {element.moduleAttributes?.items && element.moduleAttributes?.items.length > 0 ? (
        element.moduleAttributes.items.map((item: ICarouselModuleItem, i: number) => (
          <li key={i} className="list-none border-l-2 border-black ml-2 pl-2 mt-8">
            <button
              onClick={() =>
                setPage(
                  produce((draft: IPagePayload) => {
                    if (draft.resp) {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      if (el) {
                        el.moduleAttributes.items.splice(i, 1)
                        draft.selectedElement = el
                      }
                    }
                  }),
                )
              }
              className="absolute right-3"
            >
              <DeleteIcon />
            </button>
            <DynamicItemControl
              itemName="Wrapper Classes"
              itemAttribute="wrapperClasses"
              element={element}
              index={i}
              setPage={setPage}
            />
            <DynamicItemControl
              itemName="Background Image"
              itemAttribute="backgroundImage"
              element={element}
              index={i}
              setPage={setPage}
            />
            <DynamicItemControl
              itemName="Background Image Href"
              itemAttribute="backgroundImageHref"
              element={element}
              index={i}
              setPage={setPage}
            />
            <DynamicItemControl
              itemName="Background Image Alt"
              itemAttribute="backgroundImageAlt"
              element={element}
              index={i}
              setPage={setPage}
            />
            <DynamicItemControl
              itemName="Content"
              itemAttribute="content"
              element={element}
              type="textarea"
              index={i}
              setPage={setPage}
            />
            <DynamicItemControl
              itemName="Content Classes"
              itemAttribute="contentClasses"
              element={element}
              index={i}
              setPage={setPage}
            />
            <button
              onClick={() => {
                setPage(
                  produce((draft: IPagePayload) => {
                    if (draft.resp) {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      if (el) {
                        el.moduleAttributes.items.splice(i + 1, 0, {
                          wrapperClasses: 'block w-full h-auto relative',
                          backgroundImage: '',
                          backgroundImageHref: '',
                          backgroundImageAlt: '',
                          content:
                            "<div class='text-right'><h2 class='text-pethyGreen text-shadow-white text-6xl'><span class='block'>this is some exaple content</span><span class='block font-bold'>EXAMPLE HEADER</span></h2><p class='mt-8 font-bold text-gray-500 text-lg'>It's easy to make anything<br />USING TAILWINDCSS</p><a href='/sign-up' class='mt-8 rounded py-2 px-4 text-white inline-block bg-pethyGreen text-4xl font-bold'> BUTTON</a></div>",
                          contentClasses: 'top-1/4 right-1/3 absolute ',
                        })
                        draft.selectedElement = el
                      }
                    }
                  }),
                )
              }}
              className="absolute right-3 text-sm border-2 border-pethyGreen text-pethyGreen rounded-full leading-3"
            >
              <AddIcon />
            </button>
          </li>
        ))
      ) : (
        <button
          className="px-1 text-pethyGreen btn"
          title="Add Child Element"
          onClick={() => {
            setPage(
              produce((draft: IPagePayload) => {
                if (draft.resp) {
                  draft.state = 'modified'
                  const el = elementReducer(draft.resp, {
                    ids: element.ids,
                  })
                  if (el) {
                    el.moduleAttributes = el.moduleAttributes || {}
                    el.moduleAttributes.items = el.moduleAttributes?.items
                      ? el.moduleAttributes.items
                      : []

                    const items = el.moduleAttributes.items
                      ? el.moduleAttributes.items
                      : ([] as ICarouselModuleItem[])

                    el.moduleAttributes.items = [
                      ...items,
                      {
                        wrapperClasses: 'block w-full h-auto relative',
                        backgroundImage: '',
                        backgroundImageHref: '',
                        backgroundImageAlt: '',
                        content:
                          "<div class='text-right'><h2 class='text-pethyGreen text-shadow-white text-6xl'><span class='block'>this is some exaple content</span><span class='block font-bold'>EXAMPLE HEADER</span></h2><p class='mt-8 font-bold text-gray-500 text-lg'>It's easy to make anything<br />USING TAILWINDCSS</p><a href='/sign-up' class='mt-8 rounded py-2 px-4 text-white inline-block bg-pethyGreen text-4xl font-bold'> BUTTON</a></div>",
                        contentClasses: 'top-1/4 right-1/3 absolute ',
                      },
                    ]
                    draft.selectedElement = el
                  }
                }
              }),
            )
          }}
        >
          Add Banner Item
        </button>
      )}
    </>
  )
}
function DivOrLink({
  href,
  className,
  children,
}: {
  href: string
  className: string
  children: any
}) {
  return href ? (
    <a href={href} className={className}>
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  )
}
function PethyCarousel({moduleElement}: {moduleElement: ICarouselModule}) {
  const [currentSlide, setCurrentSlider] = useState(0)

  useEffect(() => {
    if (moduleElement.moduleAttributes?.autoplay === 'true') {
      const slider = setInterval(nextSlide, 5000)
      return () => clearInterval(slider)
    }
  }, [moduleElement.moduleAttributes?.autoplay])

  function nextSlide() {
    const newSlide =
      currentSlide === moduleElement.moduleAttributes.items.length - 1
        ? 0
        : currentSlide + 1
    setCurrentSlider(newSlide)
    console.log('currentSlide', currentSlide)
  }

  function prevSlide() {
    const newSlide =
      currentSlide === 0
        ? moduleElement.moduleAttributes.items.length - 1
        : currentSlide - 1
    setCurrentSlider(newSlide)
  }

  return (
    <div className="w-full flex overflow-hidden relative">
      {moduleElement.moduleAttributes?.items &&
        moduleElement.moduleAttributes.items.map((item, index) => {
          return (
            <DivOrLink
              key={index}
              href={item.backgroundImageHref || ''}
              className={
                index === currentSlide
                  ? item.wrapperClasses
                    ? item.wrapperClasses
                    : ''
                  : 'hidden'
              }
            >
              {item.backgroundImage ? (
                <img
                  src={DefaultImageHandler(
                    item.backgroundImage,
                    moduleElement?.defaultImagePath || '',
                  )}
                  alt={item.backgroundImageAlt}
                  className="block w-full h-auto max-w-full"
                />
              ) : null}
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.content || '', {
                    USE_PROFILES: {html: true},
                  }),
                }}
                className={item.contentClasses}
              />
            </DivOrLink>
          )
        })}
      <span onClick={() => prevSlide()}>
        <ArrowLeftIcon className="h-10 w-10 inline-block absolute left-2 lg:left-4 inset-y-1/2 text-gray-400 hover:opacity-80 cursor-pointer" />
      </span>

      <span onClick={() => nextSlide()}>
        <ArrowRightIcon className="h-10 w-10 inline-block absolute right-2 lg:right-4 inset-y-1/2 text-gray-400 hover:opacity-80 cursor-pointer" />
      </span>
    </div>
  )
}

export {PethyCarousel, PethyCarouselControls}
