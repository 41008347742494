import React, {useContext} from 'react'

import CopyText from '../../../atoms/copy-text'
import { IAuthUser } from '../../../Interfaces'

function ResellerView({authUser}: {authUser: IAuthUser}) {
  // if (process.env) var env = process.env
  return (
    <>
      {authUser && authUser.reseller ? (
        <div className="flex">
          <div className="flex-1">
            <div className="shadow-md m-3 py-6 px-8">
              <h2 className="text-3xl pb-2">Reseller Info</h2>
              <CopyText textId="invitation-url">
                {process.env.REACT_APP_PETHY_API_URL}?{authUser.reseller}
              </CopyText>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ResellerView
