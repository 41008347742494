import {IDynamicListModule, IDynamicListModuleItem, IPagePayload} from '../Interfaces'
import produce from 'immer'
import {elementReducer} from '../components/element-handler'
import DynamicItemControl from './DynamicItemControl'
import {AddIcon, DeleteIcon} from '../atoms/icons'
import DOMPurify from 'dompurify'

function PethyDynamicListControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: IDynamicListModule
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  return (
    <>
      <label htmlFor="text" className="block w-full">
        Text
      </label>
      <textarea
        id={'text'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.content || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'content',
          })
        }}
      />
      <label htmlFor="wrapperClasses" className="block w-full">
        Wrapper Classes
      </label>
      <input
        id={'wrapperClasses'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.wrapperClasses || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'wrapperClasses',
          })
        }}
      />
      {element.moduleAttributes?.items && element.moduleAttributes?.items.length > 0 ? (
        element.moduleAttributes.items.map((item: IDynamicListModuleItem, i: number) => (
          <li key={i} className="list-none border-l-2 border-black ml-2 pl-2 mt-8">
            <button
              onClick={() =>
                setPage(
                  produce((draft: IPagePayload) => {
                    if (draft.resp) {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      if (el) {
                        el.moduleAttributes.items.splice(i, 1)
                        draft.selectedElement = el
                      }
                    }
                  }),
                )
              }
              className="absolute right-3"
            >
              <DeleteIcon />
            </button>
            <label className='block font-bold'>List Item {i+1} </label>
            <DynamicItemControl
              itemName="Values"
              itemAttribute="values"
              type="textarea"
              element={element}
              index={i}
              setPage={setPage}
            />
            <button
              onClick={() => {
                setPage(
                  produce((draft: IPagePayload) => {
                    if (draft.resp) {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      if (el) {
                        el.moduleAttributes.items.splice(i + 1, 0, {
                          content: '',
                        })
                        draft.selectedElement = el
                      }
                    }
                  }),
                )
              }}
              className="absolute right-3 text-sm border-2 border-pethyGreen text-pethyGreen rounded-full leading-3"
            >
              <AddIcon />
            </button>
          </li>
        ))
      ) : (
        <button
          className="px-1 text-pethyGreen btn"
          title="Add Child Element"
          onClick={() => {
            setPage(
              produce((draft: IPagePayload) => {
                if (draft.resp) {
                  draft.state = 'modified'
                  const el = elementReducer(draft.resp, {
                    ids: element.ids,
                  })
                  if (el) {
                    el.moduleAttributes = el.moduleAttributes || {}
                    el.moduleAttributes.items = el.moduleAttributes?.items
                      ? el.moduleAttributes.items
                      : []

                    const items = el.moduleAttributes.items
                      ? el.moduleAttributes.items
                      : ([] as IDynamicListModuleItem[])

                    el.moduleAttributes.items = [
                      ...items,
                      {
                        content: '',
                      },
                    ]
                    draft.selectedElement = el
                  }
                }
              }),
            )
          }}
        >
          Add List Item
        </button>
      )}
    </>
  )
}

function replaceAllVariables(content: string, values: string) {
  let newContent = content || ''
  const valuesArray = values.split('||')
  for (let i = 0; i < valuesArray.length; i++) {
    newContent = newContent.replaceAll(
      `$[${i + 1}]`,
      DOMPurify.sanitize(valuesArray[i] || '', {
        USE_PROFILES: {html: true},
      }),
    )
  }
  return newContent
}

function PethyDynamicList({moduleElement}: {moduleElement: IDynamicListModule}) {
  return moduleElement.moduleAttributes?.items
    ? moduleElement.moduleAttributes.items.map((item, i) => {
        if (item.values) {
          return (
            <div
              key={i}
              className={moduleElement.moduleAttributes?.wrapperClasses || ''}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  replaceAllVariables(
                    moduleElement.moduleAttributes?.content,
                    item.values,
                  ) || '',
                  {
                    USE_PROFILES: {html: true},
                  },
                ),
              }}
            ></div>
          )
        }
      })
    : 'Add a list item...'
}

export {PethyDynamicList, PethyDynamicListControls}
