import React, {useState} from 'react'
import {IApiPayload, IAuthGroup, IAuthUser} from '../../../Interfaces'
import {useGraphql} from '../../../hooks/graphql'
import {DeleteIcon} from '../../../atoms/icons'
import ApiErrors from '../../../utils/errors'

function AuthGroupsView({
  authGroups,
  leads,
}: {
  authGroups: [IAuthGroup]
  leads: [IAuthUser]
}) {
  const {gMutation} = useGraphql()

  const [groups, setGroups] = useState<IApiPayload>({
    state: 'IDLE',
    resp: authGroups,
    errors: [],
  })
  function createGroup(n: string) {
    gMutation(
      `
        mutation createAuthGroup {
          createAuthGroup(input: {
            name: "${n}",
          }) {
            authGroups {
              id
              name
              usersCount
              websitesCount
              authGroupUsers {
                id
                role
                authUser {
                  id
                  email
                }
                authGroup {
                  id
                  name
                  usersCount
                  websitesCount
                }
              }
            }
            errors
          }
        }
      `,
      {
        setResp: setGroups,
        initData: groups.resp,
        mutationName: 'createAuthGroup',
        attributeName: 'authGroups',
      },
    )
  }
  function createAuthGroupUser(userId: string, groupId: string) {
    gMutation(
      `
        mutation createAuthGroupUser {
          createAuthGroupUser(input: {
            authGroupId: "${groupId}",
            authUserId: "${userId}",
          }) {
            authGroups {
              id
              name
              usersCount
              websitesCount
              authGroupUsers {
                id
                role
                authUser {
                  id
                  email
                }
                authGroup {
                  id
                  name
                  usersCount
                  websitesCount
                }
              }
            }
            errors
          }
        }
      `,
      {
        setResp: setGroups,
        initData: groups.resp,
        mutationName: 'createAuthGroupUser',
        attributeName: 'authGroups',
      },
    )
  }
  function changeAuthUserGroup(authGroupUserId: string, groupId: string) {
    gMutation(
      `
        mutation updateAuthGroupUser {
          updateAuthGroupUser(input: {
            authGroupId: "${groupId}",
            authGroupUserId: "${authGroupUserId}",
          }) {
            authGroups {
              id
              name
              usersCount
              websitesCount
              authGroupUsers {
                id
                role
                authUser {
                  id
                  email
                }
                authGroup {
                  id
                  name
                  usersCount
                  websitesCount
                }
              }
            }
            errors
          }
        }
      `,
      {
        setResp: setGroups,
        initData: groups.resp,
        mutationName: 'updateAuthGroupUser',
        attributeName: 'authGroups',
      },
    )
  }
  function destroyGroup(id: string) {
    gMutation(
      `
        mutation destroyAuthGroup {
          destroyAuthGroup(input: {
            authGroupId: "${id}",
          }) {
            authGroups {
              id
              name
              usersCount
              websitesCount
              authGroupUsers {
                id
                role
                authUser {
                  id
                  email
                }
                authGroup {
                  id
                  name
                  websitesCount
                  usersCount
                }
              }
            }
            errors
          }
        }
      `,
      {
        setResp: setGroups,
        initData: groups.resp,
        mutationName: 'destroyAuthGroup',
        attributeName: 'authGroups',
      },
    )
  }
  function CreateAuthGroup() {
    const [name, setName] = useState('')
    return (
      <div>
        <input
          className="input"
          placeholder="Auth Group Name"
          value={name}
          onChange={e => setName(e.target.value)}
        ></input>
        <button className="btn" onClick={() => createGroup(name)}>
          Add Auth Group
        </button>
      </div>
    )
  }
  return (
    <div className="flex">
      <div className="flex-1">
        <div className="shadow-md m-3 py-6 px-8">
          <h2 className="text-3xl pb-2">Leads</h2>
          {leads &&
            leads.map(lead => {
              return <div key={lead.id}>{lead.email}</div>
            })}
        </div>
      </div>

      <div className="flex-1">
        <div className="shadow-md m-3 py-6 px-8">
          <ApiErrors errorArrays={[{name: 'AuthGroups', errors: groups.errors}]} />
          <h2 className="text-3xl pb-2">Auth Groups</h2>
          {groups?.resp ? (
            groups.resp.map((authGroup: IAuthGroup) => {
              return (
                <div key={authGroup.id} className="w-full border-2 p-2">
                  <h3 className="text-2xl border-b-2">
                    {authGroup.name}{' '}
                    <span className="text-xs">
                      Websites: {authGroup.websitesCount} Users: {authGroup.usersCount}{' '}
                    </span>
                    {authGroup.authGroupUsers.length === 0 ? (
                      <span onClick={() => destroyGroup(authGroup.id)}>
                        <DeleteIcon />
                      </span>
                    ) : null}
                  </h3>
                  {authGroup.authGroupUsers.length > 0
                    ? authGroup.authGroupUsers.map(authGroupUser => (
                        <div key={authGroupUser.id} className="w-full flex border-b">
                          <p className="flex-1">
                            <b>{authGroupUser.authUser.email}</b>
                          </p>
                          <select
                            placeholder="Role"
                            id="role"
                            name="role"
                            defaultValue={authGroupUser.role}
                            className="flex-1"
                          >
                            <option value="MANAGER">MANAGER</option>
                            <option value="EDITOR">EDITOR</option>
                          </select>
                          <select
                            id="authGroupId"
                            name="authGroupId"
                            className="flex-1"
                            defaultValue={authGroupUser.authGroup.id}
                            onChange={e =>
                              changeAuthUserGroup(authGroupUser.id, e.target.value)
                            }
                          >
                            {groups.resp.map((authGroup2: IAuthGroup) => {
                              return (
                                <option key={authGroup2.id} value={authGroup2.id}>
                                  {authGroup2.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      ))
                    : null}
                </div>
              )
            })
          ) : (
            <p>Loading...</p>
          )}
          <CreateAuthGroup />
        </div>
      </div>
    </div>
  )
}
export default AuthGroupsView
