import React, {useEffect, useState, useRef, useContext} from 'react'

// import './websites.css'

import {Link} from 'react-router-dom'

import {AsyncJsx} from '../../components/async-jsx'

import MainMenu from '../../components/main-menu'
import Footer from '../../components/footer'

import {useForm} from 'react-hook-form'
import Modal from '../../components/modal'
import useModal from '../../hooks/useModal'

import {IWebsite, IInput, IApiPayload, IFontStyles} from '../../Interfaces'
import {useGraphql} from '../../hooks/graphql'
import ApiErrors from '../../utils/errors'
import FormField from '../../components/form-field'
import {defaultFontStyles} from '../../utils/css'

function WebsitesScreen() {
  const [websites, setWebsites] = useState<IApiPayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
  })
  const {gQuery} = useGraphql()
  const isLoading = websites.state === 'LOADING'
  const isSuccess = websites.state === 'FINALIZED'
  useEffect(() => {
    if (websites.state === 'IDLE') {
      gQuery(
        `
        {
          websites {
            id
            status
            domain
            image
            summary
            description
            defaultImagePath
            authUser {
              email
            }
            fontStyles {
              name
              classes
            }
            globalStyles
          }
        }
      `,
        {setResp: setWebsites, queryName: 'websites'},
      )
    }
  }, [gQuery, websites])

  function WebsiteFontStyles({website}: {website: IWebsite}) {
    const {isShowing, toggle} = useModal()
    const {register, handleSubmit} = useForm()
    const {gAppendMutation} = useGraphql()
    const [fontStyles, setFontStyles] = useState<IFontStyles>(
      website.fontStyles ? website.fontStyles : defaultFontStyles,
    )

    function updateWebsiteFontStyles(event: any) {
      gAppendMutation(
        `
        mutation UpdateWebsiteFontStyles{
          updateWebsiteFontStyles(
            input: {
              websiteId: "${event.websiteId}",
              fontStyles: ${JSON.stringify(event.fontStyles)},
            }
          ) {
            website {
              defaultImagePath
              authUser {
                email
              }
              id
              status
              domain
              image
              summary
              description
              fontStyles {
                name
                classes
              }
              globalStyles
            }
            errors
          }
        }
      `,
        {
          initData: websites.resp,
          setResp: setWebsites,
          appendHandler: (updatedWebsite: IWebsite) => {
            const withUpdatedWebsite = websites.resp.map((website: IWebsite) => {
              if (website.domain === updatedWebsite.domain) {
                return updatedWebsite
              } else {
                return website
              }
            })
            return withUpdatedWebsite
          },
          mutationName: 'updateWebsiteFontStyles',
          attributeName: 'website',
        },
      )
    }
    function handleStyleChange(e: any, name: string, fontStyles: IFontStyles) {
      // styles = produce((draft: IFontStyles) => {
      //   name.split
      //   draft.
      // })
      // fontStyles
      // // const newStyles = fontStyles.map(font => {
      // //   if (font.name === name) {
      // //     return {name: font.name, classes: e.target.value}
      // //   }
      // //   return font
      // // })
      // setFontStyles(newStyles)
    }

    return (
      <>
        <button className="lg-btn" onClick={() => toggle()}>
          Font Styles
        </button>
        <Modal
          isShowing={isShowing}
          hide={toggle}
          onConfirm={handleSubmit(updateWebsiteFontStyles)}
        >
          {/* <PethyFonts /> */}
          <p className="modal-head">
            Style fonts with <b>Tailwind</b>{' '}
            <a
              href="https://tailwindcss.com/docs/font-size"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="text-pethyGreen"
            >
              https://tailwindcss.com/docs/font-size.
            </a>
            <br /> And use <b>Google Fonts</b> for font Families{' '}
            <a
              href="https://fonts.google.com"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="text-pethyGreen"
            >
              https://fonts.google.com.
            </a>
            <br />
            <small>
              <i>Note: text-6xl is 'XL' not 'X1'</i>
            </small>
          </p>
          <p></p>
          <div className="text-left">
            <input
              type="hidden"
              id="websiteId"
              value={website.id}
              {...register('websiteId')}
            />
            <input
              type="hidden"
              id="fontStyles"
              value={JSON.stringify(fontStyles)}
              {...register('fontStyles')}
            />
            {Object.entries(fontStyles).forEach(([key, val]) => {
              return (
                <div key={key} className="py-2">
                  <label htmlFor={key} className={val}>
                    {key}
                  </label>
                  <input
                    id={key}
                    name={key}
                    onChange={e => handleStyleChange(e, key, fontStyles)}
                    value={val}
                    className="max-w-full input"
                  />
                </div>
              )
            })}
          </div>
          {website.fontStyles ? (
            <button
              onClick={() => {
                setFontStyles(defaultFontStyles)
              }}
              className="btn-danger"
            >
              Reset to Defaults
            </button>
          ) : null}
        </Modal>
      </>
    )
  }

  function WebsiteDetails({website}: {website: IWebsite}) {
    const {
      register,
      handleSubmit,
      formState: {errors},
      setValue,
    } = useForm()
    const [expanded, setExpanded] = useState(false)
    const {gAppendMutation} = useGraphql()
    const websiteRef = useRef(null)
    const submitRef = useRef(null)

    function updateWebsite(event: IWebsite) {
      gAppendMutation(
        `
            mutation UpdateWebsite {
              updateWebsite(
                input: {
                  websiteId: "${event.website.id}",
                  domain: "${event.website.domain}",
                  image: "${event.website.image}",
                  summary: "${event.website.summary}",
                  description: "${event.website.description}",
                  defaultImagePath: "${event.website.defaultImagePath}",
                  globalStyles: "${event.website.globalStyles.replaceAll('"', "'")}",
                }
              ) {
                website {
                  id
                  status
                  domain
                  image
                  summary
                  description
                  defaultImagePath
                  authUser {
                    email
                  }
                  fontStyles {
                    name
                    classes
                  }
                  globalStyles
                }
                errors
              }
            }
          `,
        {
          setResp: setWebsites,
          initData: websites.resp,
          appendHandler: (updatedWebsite: IWebsite) => {
            const withUpdatedWebsite = websites.resp.map((website: IWebsite) => {
              if (website.domain === updatedWebsite.domain) {
                return updatedWebsite
              } else {
                return website
              }
            })
            return withUpdatedWebsite
          },
          mutationName: 'updateWebsite',
          attributeName: 'website',
        },
      )
    }
    const fields: Array<IInput> = [
      {
        id: 'id',
        placeholder: 'Id',
        name: 'website[id]',
        type: 'hidden',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      // {
      //   id: 'status',
      //   placeholder: 'Status',
      //   name: 'website[status]',
      //   type: 'text',
      //   options: [],
      //   registerOpts: {},
      //   advMode: false
      // },
      {
        id: 'domain',
        placeholder: 'Domain',
        name: 'website[domain]',
        type: 'text',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      {
        id: 'image',
        placeholder: 'Image',
        name: 'website[image]',
        type: 'text',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      {
        id: 'defaultImagePath',
        placeholder: 'Default Image Path',
        name: 'website[defaultImagePath]',
        type: 'text',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      {
        id: 'summary',
        placeholder: 'Summary',
        name: 'website[summary]',
        type: 'text',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      {
        id: 'description',
        placeholder: 'Description',
        name: 'website[description]',
        type: 'text',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      {
        id: 'globalStyles',
        placeholder: 'Global Styles',
        name: 'website[globalStyles]',
        type: 'textarea',
        options: [],
        registerOpts: {},
        advMode: false,
      },
      // {
      //   id: 'repositoryName',
      //   placeholder: 'RepositoryName',
      //   name: 'website[repositoryName]',
      //   type: 'text',
      //   options: [],
      //   registerOpts: {},
      //   advMode: false
      // },
      // {
      //   id: 'token',
      //   placeholder: 'Token',
      //   name: 'website[token]',
      //   type: 'select',
      //   options: [
      //     website.currentToken?.name || '',
      //     ...website.availableTokens.map(a => a.name),
      //     'Remove',
      //   ],
      //   registerOpts: {},
      //   advMode: false,
      // },
    ]

    // const onFormSubmit = (event: IWebsite) => {
    const onFormSubmit = (event: any) => {
      if (websiteRef.current && submitRef.current) {
        submitRef.current.disabled = true
        websiteRef.current.classList.add('highlight-update')
      }
      setTimeout(() => {
        updateWebsite(event)
        if (websiteRef.current && submitRef.current) {
          submitRef.current.disabled = false
          websiteRef.current.classList.remove('highlight-update')
        }
      }, 500)
    }
    const onError = (event: any) => {
      console.log(event)
    }

    return expanded ? (
      <div
        className={'flex flex-col shadow-md m-3 py-6 px-8 animated-page-' + website.id}
        ref={websiteRef}
      >
        {/* <div className="page-links">
          <Link
            className="website-link text-4xl uppercase"
            to={'/websites/' + website.domain}
          >
            {website.domain}
          </Link>
          <p>
            <small>{website.authUser.email}</small>
          </p>
          <p className="py-3">{website.description}</p>
          <Link className="lg-btn" to={'/websites/' + website.domain}>
            Website Pages
          </Link>
          {website.currentToken ? (
            <Link className="lg-btn" to={'/assets/' + website.domain}>
              ADD/EDIT IMAGES OR DOCUMENTS
            </Link>
          ) : null}
          <WebsiteFontStyles website={website} />
          <hr />
        </div> */}
        <form onSubmit={handleSubmit(onFormSubmit, onError)}>
          <div className="page-details-form flex flex-wrap">
            {fields.map(field => (
              <FormField
                key={field.id}
                element={website}
                field={field}
                register={register}
                error={errors?.website}
                setValue={setValue}
              />
            ))}
            <div className="page-details-field flex-none p-3">
              <br />
              <input
                type="submit"
                className={'btn btn-primary'}
                disabled={isLoading}
                value={isLoading ? 'Processing...' : 'SAVE CHANGES'}
                ref={submitRef}
              />
              <Link
                className="flex-none justify-end btn btn-primary text-1xl"
                to={'/websites/' + website.domain}
              >
                VIEW PAGES
              </Link>
              <button
                className="flex-none justify-end btn btn-primary text-1xl"
                onClick={() => setExpanded(!expanded)}
              >
                HIDE DETAILS
              </button>
            </div>
          </div>
        </form>
        <div className="flex w-full"></div>
      </div>
    ) : (
      <div className="shadow-md p-2 m-3 flex">
        <span className="py-2 pl-4 flex-auto justify-start text-1xl uppercase">
          {website.domain}
        </span>
        <button
          className="flex-none justify-end btn btn-primary text-1xl"
          onClick={() => setExpanded(!expanded)}
        >
          DETAILS
        </button>
        <Link
          className="flex-none justify-end btn btn-primary text-1xl"
          to={'/websites/' + website.domain}
        >
          PAGES
        </Link>
      </div>
    )
  }

  return (
    <>
      <MainMenu />
      <ApiErrors errorArrays={[{name: 'websites', errors: websites.errors}]} />
      <div className="max-w-screen-lg mx-auto my-2">
        <AsyncJsx isLoading={isLoading} isSuccess={isSuccess}>
          <div className="website-index flex flex-col">
            {websites.resp &&
              websites.resp.map((website: any) => {
                return <WebsiteDetails key={website.domain} website={website} />
              })}
          </div>
        </AsyncJsx>
      </div>
      <Footer />
    </>
  )
}

export default WebsitesScreen
