import React, {useRef, useState} from 'react'

import 'react-dropzone-uploader/dist/styles.css'

import {IInput, IWebsite, IPage} from '../../Interfaces'
import {useGraphql} from '../../hooks/graphql'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'

import Modal from '../../components/modal'
import useModal from '../../hooks/useModal'

import {Range} from '../../utils/generator'
import FormField from '../../components/form-field'
import {DeleteIcon} from '../../atoms/icons'

function PageDetails({
  page,
  website,
  setWebsite,
}: {
  page: IPage
  website: IWebsite
  setWebsite: any
}) {
  const [hoverClass, setHoverClass] = useState('')
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm()
  const {isShowing, toggle} = useModal()
  const {gAppendMutation} = useGraphql()
  const pageRef = useRef(null)
  const submitRef = useRef(null)
  const [expanded, setExpanded] = useState(false)

  function destroyPage({pageId}: {pageId: string}) {
    gAppendMutation(
      `
        mutation DestroyPage{
          destroyPage(input: {pageId: "${pageId}"}) {
            page {
              id
              title
            }
          }
        }
      `,
      {
        initData: website,
        setResp: setWebsite,
        appendHandler: (destroyedPage: IPage) => {
          const withoutDestroyedPage = website.pages.filter(
            (page: IPage) => page.id !== destroyedPage.id,
          )
          return {...website, pages: [...withoutDestroyedPage]}
        },
        mutationName: 'destroyPage',
        attributeName: 'page',
      },
    )
  }

  async function updatePage(event: {page: IPage}) {
    // .classList.add("highlight-update")
    gAppendMutation(
      `
          mutation UpdatePage {
            updatePage(
              input: {
                pageId: "${page.id}",
                title: "${event.page.title}",
                slug: "${event.page.slug}",
                metaTitle: "${event.page.metaTitle}",
                metaDescription: "${event.page.metaDescription}",
                description: "${event.page.description}",
                isDeployable: "${event.page.isDeployable}",
                isTemplate: "${event.page.isTemplate}"
              }
            ) {
              page {
                id
                title
                description
                slug
                metaTitle
                metaDescription
                isDeployable
                isTemplate
              }
            }
          }
        `,
      {
        initData: website,
        setResp: setWebsite,
        appendHandler: (newPage: IPage) => {
          const withUpdatedPage = website.pages?.map((page: IPage) => {
            if (page.title === newPage.title) {
              return newPage
            } else {
              return page
            }
          })
          return {...website, pages: withUpdatedPage}
        },
        mutationName: 'updatePage',
        attributeName: 'page',
      },
    )
  }

  // const onSubmit = (event: {page: IPage}) => {
  const onSubmit = (event: any) => {
    if (pageRef.current && submitRef.current) {
      submitRef.current.disabled = true
      pageRef.current.classList.add('highlight-update')
    }
    setTimeout(() => {
      updatePage(event)
      if (pageRef.current && submitRef.current) {
        submitRef.current.disabled = false
        pageRef.current.classList.remove('highlight-update')
      }
    }, 500)
  }
  const fields: Array<IInput> = [
    {
      id: 'title',
      placeholder: 'Title',
      name: 'page[title]',
      type: 'text',
      options: [],
      registerOpts: {},
      advMode: false,
    },
    {
      id: 'slug',
      placeholder: 'Slug',
      name: 'page[slug]',
      type: 'text',
      options: [],
      registerOpts: {},
      advMode: false,
    },
    {
      id: 'metaTitle',
      placeholder: 'Meta Title',
      name: 'page[metaTitle]',
      type: 'text',
      options: [],
      registerOpts: {},
      advMode: false,
    },
    {
      id: 'metaDescription',
      placeholder: 'Meta Description',
      name: 'page[metaDescription]',
      type: 'text',
      options: [],
      registerOpts: {},
      advMode: false,
    },
    {
      id: 'isDeployable',
      placeholder: 'Is Deployable',
      name: 'page[isDeployable]',
      type: 'select',
      options: ['true', 'false'],
      registerOpts: {},
      advMode: false,
    },
    // {
    //   id: 'isTemplate',
    //   placeholder: 'Is Template',
    //   name: 'page[isTemplate]',
    //   type: 'select',
    //   options: ['true', 'false'],
    //   registerOpts: {},
    //   advMode: false,
    // },
    {
      id: 'position',
      placeholder: 'Position',
      name: 'page[position]',
      type: 'select',
      options: [...Range({start: 0, end: website.pages.length - 1})],
      registerOpts: {
        required: true,
        pattern: {
          value: /^([0-9]*)$/,
          message: 'Must be a number',
        },
      },
      advMode: false,
    },
    {
      id: 'description',
      placeholder: 'Description',
      name: 'page[description]',
      type: 'textarea',
      options: [],
      registerOpts: {},
      advMode: false,
    },
  ]
  return expanded ? (
    <div
      key={page.slug}
      ref={pageRef}
      className={
        'page-details shadow-md m-3 p-10 ' + hoverClass + ' animated-page-' + page.id
      }
    >
      <h3>
        {page.title} {hoverClass === 'highlight-update' ? 'Updating...' : ''}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-details-form flex flex-wrap">
          {fields.map((field: any) => (
            <FormField
              key={field.id}
              element={page}
              field={field}
              register={register}
              error={errors?.page}
              setValue={setValue}
            />
          ))}
          <div className="page-details-field flex-33 p-1">
            <button
              className={'btn btn-primary'}
              disabled={!!hoverClass}
              name="update-page"
              ref={submitRef}
            >
              {hoverClass ? 'Updating Page...' : 'SAVE CHANGES'}
            </button>
            <Link
              className="btn btn-primary"
              to={'/editor/' + website.domain + '/' + page.slug}
            >
              EDIT PAGE
            </Link>
            <button
              className="flex-none justify-end btn btn-primary text-1xl"
              onClick={() => setExpanded(!expanded)}
            >
              HIDE METADATA
            </button>
            <button
              onMouseOver={() => setHoverClass('highlight-destroy')}
              onMouseOut={() => setHoverClass('')}
              className="btn btn-danger"
              onClick={() => toggle()}
            >
              DELETE PAGE
            </button>
            <Modal
              isShowing={isShowing}
              hide={toggle}
              onConfirm={() => destroyPage({pageId: page.id})}
            >
              <p className="modal-head">Are you sure?</p>
              <p>
                Delete <b>{page.title}</b> page from {website.domain}
              </p>
            </Modal>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <div className="shadow-md p-2 m-3 flex">
      <span className="py-2 pl-4 flex-auto justify-start text-1xl uppercase">
        {page.title} {hoverClass === 'highlight-update' ? 'Updating...' : ''}
      </span>
      <button
        className="flex-none justify-end btn btn-primary text-1xl"
        onClick={() => setExpanded(!expanded)}
      >
        METADATA
      </button>
      <Link
        className="btn btn-primary"
        to={'/editor/' + website.domain + '/' + page.slug}
      >
        EDIT PAGE
      </Link>
      <button
        onMouseOver={() => setHoverClass('highlight-destroy')}
        onMouseOut={() => setHoverClass('')}
        className="p-1"
        onClick={() => toggle()}
      >
        <DeleteIcon />
      </button>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={() => destroyPage({pageId: page.id})}
      >
        <p className="modal-head">Are you sure?</p>
        <p>
          Delete <b>{page.title}</b> page from {website.domain}
        </p>
      </Modal>
    </div>
  )
}
export {PageDetails}
