import {INavigationHighlightsModule} from '../Interfaces'
import {useScrollPosition} from '../components/GetScrollPosition'

function PethyNavigationHighlightsControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: INavigationHighlightsModule
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  return (
    <>
      <label htmlFor="navItems" className="block w-full">
        Items to make active (query selector)
      </label>
      <input
        id={'navItems'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.navItems || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'navItems',
          })
        }}
      />
      <label htmlFor="sections" className="block w-full">
        Sections to look for ID (query selector)
      </label>
      <input
        id={'sections'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.sections || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'sections',
          })
        }}
      />
      <label htmlFor="activeClass" className="block w-full">
        Active Class
      </label>
      <input
        id={'activeClass'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.activeClass || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'activeClass',
          })
        }}
      />
      <label htmlFor="inactiveClass" className="block w-full">
        Inactive Class
      </label>
      <input
        id={'inactiveClass'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.inactiveClass || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'inactiveClass',
          })
        }}
      />
    </>
  )
}

function PethyNavigationHighlights({
  moduleElement,
  children,
}: {
  moduleElement: INavigationHighlightsModule
  children?: any
}) {
  useScrollPosition(
    ({prevPos, currPos}) => {
      const currentY = currPos.y * -1
      const sections = document.querySelectorAll(moduleElement.moduleAttributes?.sections)
      const navLi = document.querySelectorAll(moduleElement.moduleAttributes?.navItems)
      let current = ''
      sections.forEach(section => {
        const sectionTop = section.offsetTop
        if (currentY >= sectionTop - 10) {
          current = section.getAttribute('id') || 'missing-section-id'
        }
      })

      navLi.forEach(li => {
        li.classList.remove(moduleElement.moduleAttributes?.activeClass || 'active')
        li.classList.add(moduleElement.moduleAttributes?.inactiveClass || 'inactive')
        if (li.classList.contains(current)) {
          li.classList.remove(moduleElement.moduleAttributes?.inactiveClass || 'inactive')
          li.classList.add(moduleElement.moduleAttributes?.activeClass || 'active')
        }
      })
    },
    [moduleElement.moduleAttributes?.sections, moduleElement.moduleAttributes?.navItems],
    undefined,
    false,
    100,
  )
  return <>{children ? children : null}</>
}

export {PethyNavigationHighlights, PethyNavigationHighlightsControls}
