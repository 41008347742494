import {useAuth0} from '@auth0/auth0-react'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import Footer from '../components/footer'
import MainMenu from '../components/main-menu'
import UploadField from '../components/upload-field'
import {useGraphql} from '../hooks/graphql'
import {Identifiable, IWebsitePayload} from '../Interfaces'
import {useHistory, useParams} from 'react-router'
import * as ActiveStorage from '@rails/activestorage'
import { SimpleError } from '../utils/errors'

interface IWelcomeFields {
  domain: string
  designPdf: string
  description: string
}

function WelcomeScreen() {
  const {code} = useParams<Identifiable>()
  const {register, handleSubmit} = useForm()
  const {gAsyncMutation} = useGraphql()
  // const [uploadComplete, setUploadComplete] = useState<string>(null)
  const [fields, setFields] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const {isLoading, user} = useAuth0()
  // const history = useHistory()
  // if (process.env) var env = process.env
  const endpoint = `${process.env.REACT_APP_PETHY_API_URL}/rails/active_storage/direct_uploads`

  useEffect(() => {
    const awaitMutation = async function(fields: any, user: any, code: any) {
      await gAsyncMutation(
        `
          mutation createWebsite {
            createWebsite(
              input: {
                domain: "${fields.domain || ''}",
                designPdf: "${fields.designPdf}",
                description: "${fields.description || ''}",
                email: "${user.email}",
                uuid: "${code || ''}",
              }
            ) {
              website {
                domain
              }
              errors
            }
          }
        `,
        {
          mutationName: 'createWebsite',
          attributeName: 'website',
        },
      )
      history.go(0)
    }
    if (
      (fields?.designPdf && typeof fields?.designPdf === 'string') ||
      fields?.designPdf instanceof String
    ) {
      awaitMutation(fields, user, code)
    } else {
      setError('Please provide a Design PDF')
    }
  }, [fields])
  useEffect(() => {
    let isSubscribed = true
    setError(null)
    ActiveStorage.start()
    window.addEventListener('direct-upload:initialize', (event: any) => {
      const {target, detail} = event
      const {id, file} = detail
      target.insertAdjacentHTML(
        'beforebegin',
        `
          <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
            <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
            <span class="direct-upload__filename">${file.name}</span>
          </div>
        `,
      )
    })

    // window.addEventListener('direct-upload:start', (event: any) => {
    //   const {id} = event.detail
    //   const element = document.getElementById(`direct-upload-${id}`)
    //   element.classList.remove('direct-upload--pending')
    // })

    // window.addEventListener('direct-upload:progress', (event: any) => {
    //   const {id, progress} = event.detail
    //   const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    //   progressElement.style.width = `${progress}%`
    // })

    // window.addEventListener('direct-upload:error', (event: any) => {
    //   event.preventDefault()
    //   const {id, error} = event.detail
    //   const element = document.getElementById(`direct-upload-${id}`)
    //   element.classList.add('direct-upload--error')
    //   element.setAttribute('title', error)
    // })

    window.addEventListener('direct-upload:end', (event: any) => {
      // const {id, file} = event.detail
      // const element = document.getElementById(`direct-upload-${id}`)
      // element.classList.add('direct-upload--complete')
      const upload: any = document.getElementsByName('designPdf')[0]
      if (isSubscribed) {
        console.log('upload')
        setFields({...fields, designPdf: upload.value})
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [fields])

  // function setUploadComplete(uploadComplete: string, form: IWelcomeFields) {
  //   console.log('form', fields)
  //   console.log('uploadComplete', uploadComplete)
  //   gAsyncMutation(
  //     `
  //       mutation createWebsite {
  //         createWebsite(
  //           input: {
  //             domain: "${form.domain || ''}",
  //             designPdf: "${uploadComplete}",
  //             description: "${form.description || ''}",
  //             email: "${user.email}",
  //             uuid: "${code || ''}",
  //           }
  //         ) {
  //           website {
  //             domain
  //           }
  //           errors
  //         }
  //       }
  //     `,
  //     {
  //       mutationName: 'createWebsite',
  //       attributeName: 'website',
  //     },
  //   )
  // }

  // function CreateWebsite(event: IWelcomeFields) {
  function CreateWebsite(event: any) {
    setFields(event)
  }

  return (
    <>
      <MainMenu />
      
      {!isLoading ? (
        <div className="max-w-screen-lg mx-auto my-2 shadow-md m-3 py-12 px-16">
          <SimpleError error={error} />
          <h1 className="text-3xl mx-auto mb-6 px-10 text-center">
            Welcome to Pethy.app!
          </h1>
          <p className="mb-6">
            Thank you for signing up, please use the following fields to submit some basic
            information about the website you'd like built. Once you've done that we'll be
            in contact with you about getting things started.
          </p>
          <form onSubmit={handleSubmit(CreateWebsite)}>
            <div className="flex">
              <div className="flex-1 py-2 pr-4">
              <label htmlFor="domain">Domain</label>
                <input
                  type="text"
                  id="domain"
                  {...register('domain')}
                  className="input"
                  placeholder="Domain Name"
                ></input>
              </div>
              <div className="flex-1 py-2 pr-4">
                <label htmlFor="designPdf">Design PDF</label>
                <input
                  {...register('designPdf')}
                  type="file"
                  className="input"
                  data-direct-upload-url={endpoint}
                />
                {/* <UploadField
                  name="designPdf"
                  fields={fields}
                  setUploadComplete={setUploadComplete}
                  className="input"
                  register={register}
                ></UploadField> */}
              </div>
            </div>
            <div className="w-full pr-4">
              <textarea
                id="description"
                className="textarea leading-16"
                placeholder="Description of Website"
                {...register('description')}
              ></textarea>
            </div>
            <button className="btn">Submit</button>
          </form>
          <p>
            <i>You will provide Images/Documents after the initial draft is setup.</i>
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <Footer />
    </>
  )
}
export default WelcomeScreen
