import {useAuth0} from '@auth0/auth0-react'
import React, {useState} from 'react'
import {BanIcon, CheckIcon} from '../atoms/icons'
import Modal from '../components/modal'
import {useGraphql} from '../hooks/graphql'
import useModal from '../hooks/useModal'
import {IApiPayload, IAuthUser} from '../Interfaces'
import ApiErrors from '../utils/errors'

function AccountDetailsView({authUser}: {authUser: IAuthUser}) {
  const {user} = useAuth0()
  function StripePortalButton() {
    const {gQuery} = useGraphql()
    const {isShowing, toggle} = useModal()
    const [customerPortal, setCustomerPortal] = useState<IApiPayload>({
      state: 'IDLE',
      resp: null,
      errors: [],
    })

    async function stripePortalLink() {
      toggle()
      if (customerPortal.state === 'IDLE') {
        gQuery(
          `
          {
            getStripePortalLink {
              url
              state
              errors
            }
          }
        `,
          {setResp: setCustomerPortal, queryName: 'getStripePortalLink'},
        )
      }
    }

    return (
      <>
        <button
          className="btn btn-primary"
          onClick={() => {
            stripePortalLink()
          }}
        >
          Edit Billing Details
        </button>
        <Modal isShowing={isShowing} hide={toggle}>
          <p className="modal-head">Stripe Billing Portal</p>
          <p>
            Billing Details are powered by Stripe.
            <br />
            You will be redirected to a temporary URL.
          </p>
          {customerPortal.resp ? (
            customerPortal.resp.errors.length > 0 ? (
              <>
                <ApiErrors
                  errorArrays={[{name: 'portal', errors: customerPortal.resp.errors}]}
                />
              </>
            ) : (
              <a href={customerPortal.resp.url} className="btn btn-primary">
                Proceed to Billing Details
              </a>
            )
          ) : (
            <button className="btn btn-primary" disabled={true}>
              Loading...
            </button>
          )}
        </Modal>
      </>
    )
  }

  return (
    <>
      <div className="flex-1">
        <div className="shadow-md m-3 py-6 px-8">
          <h2 className="text-3xl">Account</h2>
          {/* <PethyImage path={user.picture} alt="avatar image" /><br/> */}
          {/* <small><a href="http://gravatar.com/" target="_blank" rel="noreferrer">See Gravitar to update image</a></small> */}
          <ul className="py-2 px-0">
            <dt>
              <b>Email</b>
            </dt>
            <dl>
              {user.email}
              {user.email_verified ? (
                <>
                  <CheckIcon className="text-pethyGreen" />
                  <span>Verified </span>
                </>
              ) : (
                <>
                  <BanIcon className="text-red-600" /> <span>Not Verified</span>
                </>
              )}
            </dl>
          </ul>
          {authUser ? (
            <StripePortalButton />
          ) : (
            <>
              <hr />
              <p className='pt-2'>
                Your billing info can be edited here once you have finished the
                on-boarding process.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountDetailsView
