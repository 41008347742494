import React from 'react'
import MainMenu from '../components/main-menu'
import Footer from '../components/footer'
import {PethyImage} from '../atoms/image'
import TitleSubtitleSection from '../modules/title-subtitle-section'
import LargeNumberedList from '../modules/large-numbered-list-item'
import {useAuth0} from '@auth0/auth0-react'
import IconColumns from '../modules/icon-columns'
import ContactForm from '../modules/ContactForm'

function Home() {
  const {loginWithRedirect} = useAuth0()
  return (
    <>
      <div onClick={loginWithRedirect} className="cursor-pointer relative">
        <PethyImage
          name="pethy-home-banner-welcome.png"
          alt="Welcome to Pethy, have a website built for you"
          className="w-full"
        />
        {/* <div className="absolute bottom-28 right-32 w-64">
          <Rocket wrapperClass="w-full" />
        </div> */}
      </div>
      <div className="max-w-screen-lg mx-auto p-8">
        <h1 className="text-3xl text-pethyGreen mx-auto my-10 px-10">
          Your website is one of the most important marketing tools you have... But it
          shouldn't have to cost an arm and a leg to get one.
        </h1>
        <p className="my-8">
          Pethy.app was created with the goal to make launching your website more
          affordable and to allow for you to easily add/remove/edit content using Pethy's
          simple-to-use editing platform.
        </p>
        <p className="my-8">
          Prices start at $750 for the initial build and then $10 per month to continue to
          use the platform.
        </p>
      </div>
      <TitleSubtitleSection
        title="Pricing"
        subtitle="Simple, custom, websites at an affordable price"
        bg="bg-sky-900 text-white"
      >
        <div className="flex-1 p-4 bg-white text-black">
          <p className="uppercase text-pethyGreen text-sm">Initial Build</p>
          <p className="text-2xl">Starting at $750</p>
          <p className="my-4 text-sm">
            Our custom build plans start at $750, but will vary based on your needs and
            the scope of your project. A typical starting build is a handful of page
            layouts with a contact form.
          </p>
        </div>
        <div className="flex-1 p-4 bg-pethyVintageYellow text-black">
          <p className="uppercase text-pethyGreen text-sm">Pethy Service</p>
          <p className="text-2xl">$10 per month</p>
          <p className="my-4 text-sm">
            Using our platform is $10 a month (hosting included). You'll be able to point
            your domain to Pethy for custom domains. You will also have the option of
            hosting elsewhere, please don't hesitate to reach out to us if you have
            question!
          </p>
        </div>
      </TitleSubtitleSection>

      <div className="bg-gray-100">
        <div className="max-w-screen-lg mx-auto p-8">
          <h2 className="text-3xl font-bold text-center">The Process</h2>
          <p className="mb-6 text-center">
            What it takes to get your website launched from start to finish
          </p>
          <LargeNumberedList>
            <div className="my-4 w-full">
              <p>
                <b>Contact our team.</b>
              </p>
              <p>
                Once you click the green button below, our team will be notified that you
                are ready to begin building your website. We'll get in touch with you to
                discuss the scope of the project.
              </p>
            </div>
            <div className="my-4 w-full">
              <p>
                <b>Assets we'll need from you:</b>
              </p>
              <ul>
                <li>Domain name (whether you own it yet or not)</li>
                <li>
                  PDF of the design. (Don't have a design yet? We can get you in touch
                </li>
                with someone to help!)
                <li>Images/Documents to be displayed on website</li>
                <li>Description of the website</li>
              </ul>
            </div>
            <div className="my-4 w-full">
              <p>
                <b>Work with our developer to get your site set up</b>
              </p>
              <ul>
                <li>
                  A meeting will be scheduled where your assets and content will be reviewed. If everything looks good,
                  an initial payment will be processed and a draft will of the site will begin.
                </li>
                <li>
                  You will be contacted via email when your site is ready for review in
                  the editing platform.
                </li>
                <li>
                  Depending on the scope of the project there will be follow up emails
                  and/or video calls to make sure everything is created the way you
                  envisioned it.
                </li>
              </ul>
            </div>
            <div className="my-4 w-full">
              <p>
                <b>Launch it!</b>
              </p>
              <ul>
                <li>
                  When the website is ready to go live, you will receive an invoice for
                  the development time. (You will receive an initial estimate at the start
                  of your project and during the process you will be notified if costs are
                  expected to go beyond the initial estimate.)
                </li>
                <li>After payment is made, the site will go live</li>
              </ul>
            </div>
          </LargeNumberedList>
          <div className="w-full text-center">
            <a
              aria-label="get started now"
              title="get started now"
              className="bg-pethyGreen text-white uppercase m-1 inline-block py-4 px-6 rounded cursor-pointer"
              onClick={loginWithRedirect}
            >
              Get Started Now
            </a>
          </div>
        </div>
      </div>

      <div className="p-8">
        <h2 className="text-3xl font-bold text-center">
          On-going benefits of using Pethy.app
        </h2>
        <p className="mb-6 text-center">Your subscription includes:</p>
        <div className="max-w-screen-lg mx-auto">
          <IconColumns>
            <>
              <PethyImage
                className="aspect-auto"
                name="maintenance-bot.png"
                alt="up to date"
              />
              <p className="mb-4">
                <b>Maintenance, security updates and keeping things "up to date"</b>
              </p>
              <p>
                It's important that your site is maintained and secure for both you and
                your users. We take care of all of that for you.
              </p>
            </>
            <>
              <PethyImage
                className="aspect-auto"
                name="simple-editing-icon.png"
                alt="simple editing"
              />
              <p className="mb-4">
                <b>Access to the simple-to-use editing platform</b>
              </p>
              <p>
                You will have easy access to updating and editing your content using the
                Pethy.app platform.
              </p>
            </>
            <>
              <PethyImage
                className="aspect-auto"
                name="mobile-friendly.png"
                alt="mobile friendly"
              />
              <p className="mb-4">
                <b>Mobile friendliness</b>
              </p>
              <p>
                With over 60% of web traffic coming from mobile devices, it's important
                that your site functions well when using phone.
              </p>
            </>
          </IconColumns>
          <IconColumns>
            <>
              <PethyImage
                className="aspect-auto"
                name="no-down-time-umbrella.png"
                alt="no down time"
              />
              <p className="mb-4">
                <b>Superior systems to help prevent your site from going "down".</b>
              </p>
              <p>
                We utilize the powerhouse of{' '}
                <a
                  href="https://www.cloudflare.com/learning/serverless/glossary/what-is-edge-computing/"
                  className="text-pethyGreen"
                >
                  Cloudflare's Edge
                </a>{' '}
                to provide you the best performence using a{' '}
                <a
                  href="https://www.cloudflare.com/learning/cdn/what-is-a-cdn/"
                  className="text-pethyGreen"
                >
                  CDN (Content Delivery Network)
                </a>
              </p>
            </>
            <>
              <PethyImage
                className="aspect-auto"
                name="lightening-fast-load.png"
                alt="lightning fast load"
              />
              <p className="mb-4">
                <b>Lightening fast page load times.</b>
              </p>
              <p>
                We work to stay up on the latest web development trends and keep our code
                neat n' tidy so your site can run as fast as possible. No endless loading
                bars here.{' '}
              </p>
            </>
            <>
              <PethyImage
                className="aspect-auto"
                name="further-development-meetings.png"
                alt="further development"
              />
              <p className="mb-4">
                <b>Additional Development Available.</b>
              </p>
              <p>
                A meeting can be scheduled for free up to twice a month to discuss further
                development on your website, this can be expanded for a fee.
              </p>
            </>
          </IconColumns>
        </div>
      </div>
      <div className="p-8">
        <h2 className="py-4 text-3xl font-bold text-center">
          Contact us
        </h2>
        <div className="flex justify-center">
          <div className="w-full max-w-xl">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  )
}

function About() {
  return (
    <>
      <MainMenu />
      <Home />
      <Footer />
    </>
  )
}

export default About
