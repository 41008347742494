import React from 'react'
import gsap from 'gsap'
import {useAuth0} from '@auth0/auth0-react'
import {Link, useLocation} from 'react-router-dom'

import {BarsIcon} from '../atoms/icons'
import {PethyImage} from '../atoms/image'

function MainMenu() {
  const location = useLocation()
  // useLayoutEffect(() => {
  //   if (location.pathname === '/') {
  //     let tl = gsap.timeline()
  //     tl.from('.animated-pethy-nav', {duration: 0.3, opacity: 0, y: -60})
  //       .from('animated-nav-logo', {duration: 0.3, opacity: 0, x: -20})
  //       .from('.animated-nav-item', {
  //         duration: 0.5,
  //         opacity: 0,
  //         y: 'random(-30, 30)',
  //         stagger: 0.1,
  //       })
  //   } else {
  //     let tl = gsap.timeline({repeat: 1, yoyo: true})
  //     tl.to('.animated-nav-item.animated-active', {
  //       duration: 0.2,
  //       y: '-0.2rem',
  //     })
  //   }
  // }, [location.pathname])

  const {loginWithRedirect, isAuthenticated, logout} = useAuth0()
  const logoutAuth0 = () => {
    logout({returnTo: window.location.origin})
  }
  // const toggleNav = () => {}
  function NavLink({
    defaultClass,
    className,
    to,
    id,
    ariaLabel,
    style,
    title,
    children,
    onClick,
  }: {
    defaultClass?: string
    className?: string
    id?: string
    style?: any
    to?: string
    ariaLabel?: string
    title?: string
    children?: any
    onClick?: any
  }) {
    const active = location.pathname.includes(to)
    const classes = className ? className : ''
    return onClick ? (
      <button
        type="button"
        className={`${
          defaultClass
            ? defaultClass
            : 'animated-nav-item hover:bg-gray-100 flex items-center flex-none px-3 uppercase'
        } ${classes} ${active ? 'active animated-active' : ''}`}
        id={id}
        aria-label={ariaLabel}
        aria-current={active ? 'page' : false}
        title={title}
        style={style}
        onClick={onClick}
      >
        {children}
      </button>
    ) : (
      <Link
        className={`${
          defaultClass
            ? defaultClass
            : 'animated-nav-item hover:bg-gray-100 flex items-center flex-none px-3 uppercase'
        } ${classes} ${active ? 'active animated-active' : ''}`}
        id={id}
        to={to}
        aria-label={ariaLabel}
        aria-current={active ? 'page' : false}
        title={title}
        style={style}
      >
        {children}
      </Link>
    )
  }

  return (
    <nav className="animated-pethy-nav flex">
      <NavLink
        to="/"
        defaultClass="animated-nav-logo p-1"
        id="nav-logo"
        // style={{color: 'black', textDecoration: 'none', padding: 10, opacity: 100}}
      >
        <PethyImage
          name="pethy-logo.png"
          alt="Pethy Logo"
          title="Pethy.app - A service of Charuwts, LLC"
          style={{maxWidth: 150, margin: 5}}
        />
      </NavLink>
      <div className="flex justify-end justify-self-end flex-grow xs:hidden md:flex">
        {/* <a
          href="https://docs.pethy.app"
          aria-label="articles"
          title="articles"
          className="animated-nav-item hover:bg-gray-100 flex items-center flex-none px-3 uppercase"
          >
          <span className="animated-menu-text pl-2">Articles</span>
        </a> */}
        {isAuthenticated ? (
          <>
            <NavLink to="/about" ariaLabel="about" title="about">
              <span className="animated-menu-text pl-2">About</span>
            </NavLink>
            <NavLink
              to="/websites"
              ariaLabel="website management"
              title="website management"
            >
              <span className="animated-menu-text pl-2">Websites</span>
            </NavLink>
            <NavLink to="/account" ariaLabel="account of current user" title="account">
              <span className="animated-menu-text pl-2">Account</span>
            </NavLink>
            <NavLink ariaLabel="Log out" title="Log Out" onClick={logoutAuth0}>
              <span className="animated-menu-text pl-2">Log Out</span>
            </NavLink>
          </>
        ) : (
          <NavLink ariaLabel="Log in" title="Log In" onClick={loginWithRedirect}>
            <span className="animated-menu-text pl-2">Log In</span>
          </NavLink>
        )}
      </div>
      <button
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
        className="md:hidden"
        aria-label="Toggle navigation"
        // onClick={() => toggleNav()}
      >
        <BarsIcon />
      </button>
    </nav>
  )
}

export default MainMenu
