import React from 'react'

function Footer() {
  return (
    <footer
      className='py-4 mt-4 text-center'
      // style={{
      //   position: 'relative',
      //   top: '80vh',
      //   bottom: 0,
      //   textAlign: 'center',
      //   backgroundColor: '#062846',
      //   color: 'white',
      // }}
    >
      Pethy is a service of{' '}
      <a href="https://www.charuwts.com" className='font-bold'>
        Charuwts, LLC
      </a>
    </footer>
  )
}
export default Footer
