import React, { useLayoutEffect, useRef } from 'react'

import MainMenu from '../components/main-menu'
import Footer from '../components/footer'

function NotFoundScreen() {
  // const fadeRef = useRef<any>();

  // useLayoutEffect(() => {
  //   if (fadeRef.current) {
  //     fadeRef.current.classList.remove('opacity-0')
  //     fadeRef.current.classList.add('opacity-100')
  //   }
  // })
  return (
    <>
      <MainMenu />
        <h1 className="text-center p-4">🤔 Pethy.app does not know what to do at this url. <br /><a href="/" className='btn'>Return to home.</a></h1>
      <Footer />
    </>
  )
}

export default NotFoundScreen
