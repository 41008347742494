import React, {useContext, useEffect, useState} from 'react'

import {AsyncJsx} from '../../components/async-jsx'
import MainMenu from '../../components/main-menu'
import Footer from '../../components/footer'

import {useGraphql} from '../../hooks/graphql'
import ApiErrors from '../../utils/errors'

import {
  Identifiable,
  IWebsite,
  IPage,
  IWebsitePayload,
  IDeployState,
  ILog,
} from '../../Interfaces'
import {useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'

import {PageDetails} from './_page_details'
import {ModeContext} from '../../ModeContext'

import Modal from '../../components/modal'
import useModal from '../../hooks/useModal'

function WebsiteScreen() {
  const {domain} = useParams<Identifiable>()
  const [website, setWebsite] = useState<IWebsitePayload>({
    state: 'IDLE',
    resp: {} as IWebsite,
    errors: [],
  })
  const {gQuery} = useGraphql()
  const isLoading = website.state === 'LOADING'
  const isSuccess = website.state === 'FINALIZED'
  const {AdvModeToggle} = useContext(ModeContext)

  useEffect(() => {
    if (website.state === 'IDLE') {
      gQuery(
        `
          {
            website(domain: "${domain}") {
              id
              status
              domain
              image
              summary
              description
              globalStyles
              pages {
                id
                title
                description
                slug
                position
                metaTitle
                metaDescription
                isDeployable
                isTemplate
              }
              logs {
                title
                description
              }
            }
          }
          `,
        {setResp: setWebsite, queryName: 'website'},
      )
    }
  }, [gQuery, domain])

  function Deploy({websiteId, force = 'false'}: {websiteId: string, force?: string}) {
    const {gMutation} = useGraphql()
    const [deployState, setDeployState] = useState<IDeployState>({
      state: 'IDLE',
      resp: 'IDLE',
      errors: [],
    })

    function deployWebsite(id: string) {
      gMutation(
        `
        mutation deploy {
          deploy (
            input: {
              websiteId: ${id},
              force: "${force}"
            }
            ) {
            state
            errors
          }
        }
      `,
        {
          setResp: setDeployState,
          initData: deployState,
          mutationName: 'deploy',
          attributeName: 'state',
        },
      )
    }
    return (
      <button
        disabled={deployState.state === 'LOADING'}
        onClick={() => deployWebsite(websiteId)}
        className="badge badge-primary"
      >
        {deployState.resp !== 'IDLE' ? 'Deploy Scheduled' : force === 'true' ? 'Force Deploy' : 'Deploy'}
      </button>
    )
  }

  function CreatePageButton({currentWebsite}: {currentWebsite: IWebsite}) {
    const {gAppendMutation} = useGraphql()
    const pages = currentWebsite.pages || []
    function createPage({websiteId, title}: {websiteId: string; title: string}) {
      gAppendMutation(
        `
        mutation CreatePage{
          createPage(input: {websiteId: "${websiteId}", title: "${title}"}) {
            errors
            page {
              id
              title
              slug
            }
          }
        }
      `,
        {
          initData: currentWebsite,
          setResp: setWebsite,
          appendHandler: (newPage: IPage) => {
            return {...currentWebsite, pages: [newPage, ...pages]}
          },
          mutationName: 'createPage',
          attributeName: 'page',
        },
      )
    }

    const {register, handleSubmit} = useForm()
    const onSubmit = (event: any) => {
      createPage({
        websiteId: currentWebsite.id,
        title: event.title,
      })
    }
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="create-page">
          <input
            placeholder="Page Title"
            id="page-id"
            className="input"
            {...register('title')}
            type="text"
          ></input>
          <button className="btn">Create Page</button>
        </div>
      </form>
    )
  }

  function Logs({website}: {website: IWebsitePayload}) {
    const {gQuery, gMutation} = useGraphql()
    const {isShowing, toggle, setIsShowing} = useModal()
    const [logs, setLogs] = useState<any>([])

    async function getLogs() {
      setIsShowing(true)
      gQuery(
        `
          {
            logs(domain: "${website.resp?.domain}") {
              id
              title
              description
            }
          }
          `,
        {setResp: setLogs, queryName: 'logs'},
      )
    }

    async function clearLogs() {
      setIsShowing(false)
      gMutation(
        `
          mutation destroyWebsiteLogs {
            destroyWebsiteLogs(input: {websiteId: "${website.resp?.id}"}) {
              errors
            }
          }
          `,
        {
          setResp: setLogs,
          initData: logs.resp,
          mutationName: 'destroyWebsiteLogs',
          attributeName: 'errors',
        },
      )
    }

    return (
      <>
        <button
          className="btn btn-primary"
          onClick={() => {
            getLogs()
          }}
        >
          Get Recent Logs
        </button>
        <Modal isShowing={isShowing} hide={toggle} closeText="Close">
          {logs.resp ? (
            <>
              <div className="bg-gray-100 p-3 text-left">
                <code>
                  {logs.resp.length > 0
                    ? logs.resp.map((log: ILog) => (
                        <p
                          key={log.id}
                          className={log.title.includes('Error') ? 'text-red-500' : ''}
                        >
                          {log.description}
                        </p>
                      ))
                    : 'No Recent logs found.'}
                </code>
              </div>
              <button onClick={() => clearLogs()} className="btn">
                Clear Logs
              </button>
            </>
          ) : (
            <p>Getting Recent Logs...</p>
          )}
        </Modal>
      </>
    )
  }

  return (
    <>
      <MainMenu />
      <ApiErrors errorArrays={[{name: 'website', errors: website.errors}]} />
      <div className="max-w-screen-lg mx-auto my-2 p-3">
        <AsyncJsx isLoading={isLoading} isSuccess={isSuccess}>
          {website.resp ? (
            <div className="website-view">
              <article>
                <header>
                  <h2 id="website-title" className="inline uppercase">
                    {website.resp.domain}{' '}
                  </h2>
                  <Deploy websiteId={website.resp.id} />
                  {/* <Deploy websiteId={website.resp.id} force='true' /> */}
                  <Logs website={website} />
                  <a href={`/website-download/${website.resp.id}`} className="btn btn-primary">Download ZIP</a>
                </header>
                <section>
                  <CreatePageButton currentWebsite={website.resp} />
                  <h3>
                    Pages <AdvModeToggle />
                  </h3>
                  <div className="pages-index">
                    {website.resp.pages && website.resp.pages.length > 0 ? (
                      // Filter handles null pages returned on creation failure
                      website.resp.pages
                        .filter(p => p !== null)
                        .map(page => (
                          <PageDetails
                            key={page.slug}
                            page={page}
                            website={website.resp}
                            setWebsite={setWebsite}
                          />
                        ))
                    ) : (
                      <p>
                        <i>Enter a title and click create page.</i>
                      </p>
                    )}
                  </div>
                </section>
              </article>
            </div>
          ) : null}
        </AsyncJsx>
      </div>
      <Footer />
    </>
  )
}

export default WebsiteScreen
