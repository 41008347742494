import React from 'react'

import {Auth0Provider} from '@auth0/auth0-react'
// import {Auth0Provider} from './MockAuth0Context'
// import {FetchProvider} from './FetchContext'
import {ModeProvider} from './ModeContext'
import {BrowserRouter as Router} from 'react-router-dom'
import gsap from 'gsap'

function AppProviders({children}: {children: any}) {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      redirectUri={`${window.location.origin}`}
      audience={process.env.REACT_APP_AUTH0_PETHY_AUDIENCE}
    >

        <ModeProvider>
          <Router>{children}</Router>
        </ModeProvider>

    </Auth0Provider>
  )
}

export default AppProviders
