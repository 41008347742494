import React from 'react'
import {ContentElement} from '../components/ContentElement'
import {IToggleContentModule} from '../Interfaces'

function PethyToggleContentControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: IToggleContentModule
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  return (
    <>
      <label htmlFor="trigger" className="block w-full">
        Event Trigger
      </label>
      <select
        id="trigger"
        className="select"
        value={element.moduleAttributes?.trigger || 'CLICK'}
        onChange={e =>
          handleElementChange({
            newValue: e.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'trigger',
          })
        }
      >
        <option value="CLICK">Click</option>
        <option value="HOVER">Hover</option>
      </select>
      <label htmlFor="classToToggle" className="block w-full">
        Class To Toggle
      </label>
      <input
        id={'classToToggle'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.classToToggle || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'classToToggle',
          })
        }}
      />
      <label htmlFor="wrapperClasses" className="block w-full">
        Wrapper Classes
      </label>
      <input
        id={'wrapperClasses'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.wrapperClasses || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value,
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'wrapperClasses',
          })
        }}
      />
      <label htmlFor="toggleOnClasses" className="block w-full">
        Toggled On Classes
      </label>
      <input
        id={'toggleOnClasses'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.toggleOnClasses || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value.replace(/ +/g, ' '),
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'toggleOnClasses',
          })
        }}
      />
      <label htmlFor="toggleOffClasses" className="block w-full">
        Toggled Off Classes
      </label>
      <input
        id={'toggleOffClasses'}
        className="block w-full input border p-2"
        value={element.moduleAttributes?.toggleOffClasses || ''}
        onChange={event => {
          event.persist()
          handleElementChange({
            newValue: event.target.value.replace(/ +/g, ' '),
            element: element,
            setPage: setPage,
            attr: 'moduleAttributes',
            attr2: 'toggleOffClasses',
          })
        }}
      />
    </>
  )
}

function PethyToggleContent({
  moduleElement,
  children,
}: {
  moduleElement: IToggleContentModule
  children?: any
}) {
  function toggleOn(el: Element) {
    el.classList.add(
      ...(moduleElement.moduleAttributes?.toggleOnClasses.split(' ') || 'toggledOn'),
    )
    el.classList.remove(
      ...(moduleElement.moduleAttributes?.toggleOffClasses.split(' ') || 'toggledOff'),
    )
  }
  function toggleOff(el: Element) {
    el.classList.remove(
      ...(moduleElement.moduleAttributes?.toggleOnClasses.split(' ') || 'toggledOn'),
    )
    el.classList.add(
      ...(moduleElement.moduleAttributes?.toggleOffClasses.split(' ') || 'toggledOff'),
    )
  }
  function toggleContentHandler(toggle: 'toggle' | 'toggleOn' | 'toggleOff') {
    const els = document.getElementsByClassName(
      moduleElement.moduleAttributes?.classToToggle,
    )
    if (els) {
      for (let index = 0; index < els.length; index++) {
        const isInactive = moduleElement.moduleAttributes.toggleOffClasses
          .split(' ')
          .every(function(c) {
            return els[index].classList.contains(c)
          })
        if (toggle === 'toggleOn') {
          toggleOn(els[index])
        } else if (toggle === 'toggleOff') {
          toggleOff(els[index])
        } else if (isInactive) {
          toggleOn(els[index])
        } else {
          toggleOff(els[index])
        }
      }
    }
  }

  return (
    <div
      onClick={() =>
        moduleElement.moduleAttributes?.trigger === 'CLICK'
          ? toggleContentHandler('toggle')
          : null
      }
      onMouseOver={() =>
        moduleElement.moduleAttributes?.trigger === 'HOVER'
          ? toggleContentHandler('toggleOn')
          : null
      }
      onMouseOut={() =>
        moduleElement.moduleAttributes?.trigger === 'HOVER'
          ? toggleContentHandler('toggleOff')
          : null
      }
      className={moduleElement.moduleAttributes?.wrapperClasses}
    >
      {children ? children : null}
    </div>
  )
}
export {PethyToggleContent, PethyToggleContentControls}
