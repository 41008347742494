import React from 'react'

import produce from 'immer'
import {IDynamicElement, IElement, IImage, IPage, IPagePayload} from '../Interfaces'

import allModules from '../modules/all'
import {pethyClasses} from '../utils/css'
import DefaultImageHandler from './DefaultImageHandler'
import DOMPurify from 'dompurify'
import {marked} from 'marked'
import {v5 as uuidv5, v1 as uuidv1} from 'uuid'

function generateNewIds(newElement: IElement, parentElement: IElement) {
  const newId = uuidv5('mario-rpg', uuidv1())
  newElement.id = newId
  newElement.parentIds = parentElement.ids
  newElement.ids = [...parentElement.ids, newId]

  if (newElement.childElements) {
    newElement.childElements.forEach(childElement => {
      generateNewIds(childElement, newElement)
    })
  }
  return newElement
}

function DynamicElement({
  element,
  id,
  dataPethyModuleId,
  className,
  action,
  style,
  children,
}: {
  id: string
  dataPethyModuleId: string
  className: string
  element: IElement
  action?: any
  style: object
  children: any
}) {
  const obj = {
    PARAGRAPH: 'p',
    TEXT: 'div',
    SPAN: 'span',
    MAIN: 'main',
    ARTICLE: 'article',
    IFRAME: 'iframe',
    VIDEO: 'video',
    LINK: 'a',
    QUOTE: 'blockquote',
    FOOTER: 'footer',
    HEADER: 'header',
    BUTTON: 'button',
    SECTION: 'section',
    NAV: 'nav',
    ASIDE: 'aside',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    UL: 'ul',
    OL: 'ol',
    LI: 'li',
  }

  if (element.elementTag === 'NONE') return null
  if (element.elementTag === 'VIDEO') {
    return (
      <video autoPlay={true} loop={true} muted={true} width="100%" height="100%">
        <source src={element.elementAttributes?.src} type="video/mp4"></source>
      </video>
    )
  }
  if (element.elementTag === 'IFRAME') {
    return (
      <iframe
        src={element.elementAttributes?.src}
        className={className}
        data-pethy-module-id={dataPethyModuleId}
        style={{...style}}
        frameBorder="0"
        height="100%"
        width="100%"
        allow={element.elementAttributes?.allow}
      />
    )
  }
  if (element.elementTag === 'IMAGE')
    return (
      <ImageElement
        onClick={e => {
          action ? action(e) : null
        }}
        element={element as IImage}
      />
    )

  const DynamicTag = obj[element.elementTag] || 'div'

  return (
    <>
      {element.elementTag === 'LINK' ? (
        <a
          id={id || ''}
          data-pethy-module-id={dataPethyModuleId}
          className={className}
          onClick={e => {
            action ? action(e) : null
          }}
          style={{...style}}
          href={element.elementAttributes?.href}
        >
          {element.moduleAttributes?.value}
          {children}
        </a>
      ) : element.enum === 'INLINE' && element.moduleAttributes?.value ? (
        <DynamicTag
          id={id || null}
          data-pethy-module-id={dataPethyModuleId}
          className={className}
          onClick={e => {
            action ? action(e) : null
          }}
          style={{...style}}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              marked.parseInline(element.moduleAttributes?.value) || '',
              {
                USE_PROFILES: {html: true},
              },
            ),
          }}
        />
      ) : (
        <DynamicTag
          id={id || null}
          data-pethy-module-id={dataPethyModuleId}
          className={className}
          onClick={e => {
            action ? action(e) : null
          }}
          style={{...style}}
        >
          {element.moduleAttributes?.value}
          {children}
        </DynamicTag>
      )}
    </>
  )
}

function DynamicModule({element, children}: {element: IElement; children: any}) {
  const TagName = allModules[element.moduleName]?.module
  if (TagName) {
    return <TagName moduleElement={element}>{children}</TagName>
  } else {
    return <div>No module defined for {element.moduleName}</div>
  }
}

function DynamicModuleControls({
  moduleName,
  element,
  setPage,
  handleElementChange,
}: {
  moduleName: string
  element: IElement
  setPage: any
  handleElementChange: any
}) {
  if (moduleName) {
    const TagName = allModules[moduleName]?.controls
    if (TagName) {
      return (
        <TagName
          element={element}
          handleElementChange={handleElementChange}
          setPage={setPage}
        />
      )
    } else {
      return null
    }
  }
  return null
}

// Use ids to recursively select different levels of elements
function elementReducer(
  draft: IPage,
  action: {ids: Array<string>},
): IElement | undefined {
  let currentElement = draft as IPage | IElement | undefined
  for (let index = 0; index < action.ids.length; index++) {
    currentElement = currentElement?.childElements.find(
      (el: IElement) => el.id === action.ids[index],
    )
  }
  return currentElement as IElement | undefined
}

// Usage: New Value is passed in along with the element to be changed
//  The element has the ids needed for the elementReducer
// to retrieve the element from the payload
//  Because of produce/draft the element can be changed in place with the new value
// and then the new drafted state can be set
function handleElementChange({
  newValue,
  element,
  setPage,
  attr,
  attr2,
}: {
  newValue: string
  element: IElement
  setPage: (object: object) => void
  attr: string
  attr2?: string
}) {
  // Set State
  setPage(
    // use produce to treat state as mutable
    produce((draft: IPagePayload) => {
      // indicate changes have been applied and need to be saved to persist
      draft.state = 'modified'
      if (draft.resp) {
        const el: IDynamicElement | undefined = elementReducer(draft.resp, {
          ids: element.ids,
        })
        if (el) {
          if (attr2) {
            el[attr] = el[attr] ? el[attr] : {}
            el[attr][attr2] = String(newValue)
          } else {
            el[attr] = String(newValue)
          }
          // Set selected element rerender maintains selection and displays latest values
          draft.selectedElement = el
        }
      }
    }),
  )
}
function ImageElement({element, onClick}: {element: IImage; onClick: any}) {
  return (
    <img
      onClick={onClick}
      src={DefaultImageHandler(
        element.elementAttributes?.src || '',
        element?.defaultImagePath || '',
      )}
      alt={element.elementAttributes?.alt}
      width={element.elementAttributes?.width}
      height={element.elementAttributes?.height}
      title={element.elementAttributes?.title}
      id={element.elementAttributes?.id}
      className={element.elementAttributes?.className || pethyClasses.imageDefaults}
    />
  )
}
export {
  handleElementChange,
  elementReducer,
  ImageElement,
  DynamicModule,
  DynamicModuleControls,
  DynamicElement,
  generateNewIds,
}
