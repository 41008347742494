import produce from 'immer'
import {elementReducer} from '../components/element-handler'
import {IDynamicElement, IPagePayload} from '../Interfaces'

function DynamicItemControl({
  index,
  element,
  itemAttribute,
  itemName,
  listName = 'items',
  setPage,
  type = 'text',
}: {
  index: number
  element: IDynamicElement
  itemAttribute: string
  itemName: string
  listName?: string
  setPage: (val: object) => void
  type?: string
}) {
  return (
    <>
      <label
        htmlFor={
          element.moduleAttributes[listName][index][itemAttribute] +
          '-' +
          itemAttribute +
          '-' +
          index
        }
      >
        {itemName}
      </label>
      {type === 'text' ? (
        <input
          id={
            element.moduleAttributes[listName][index][itemAttribute] +
            '-' +
            itemAttribute +
            '-' +
            index
          }
          className="w-full input"
          value={element.moduleAttributes[listName][index][itemAttribute] || ''}
          onChange={event => {
            event.persist()
            setPage(
              produce((draft: IPagePayload) => {
                if (draft.resp) {
                  draft.state = 'modified'
                  const el = elementReducer(draft.resp, {
                    ids: element.ids,
                  })
                  if (el) {
                    el.moduleAttributes[listName][index][itemAttribute] = event.target.value
                    draft.selectedElement = el
                  }
                }
              }),
            )
          }}
        />
      ) : type === 'textarea' ? (
        <textarea
          id={
            element.moduleAttributes[listName][index][itemAttribute] +
            '-' +
            itemAttribute +
            '-' +
            index
          }
          className="w-full input bg-gray-100 p-2"
          value={element.moduleAttributes[listName][index][itemAttribute] || ''}
          onChange={event => {
            event.persist()
            setPage(
              produce((draft: IPagePayload) => {
                if (draft.resp) {
                  draft.state = 'modified'
                  const el = elementReducer(draft.resp, {
                    ids: element.ids,
                  })
                  if (el) {
                    el.moduleAttributes[listName][index][itemAttribute] = event.target.value
                    draft.selectedElement = el
                  }
                }
              }),
            )
          }}
        />
      ) : null}
    </>
  )
}
export default DynamicItemControl
