import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {ModeContext} from '../../ModeContext'
import {v5 as uuidv5, v1 as uuidv1} from 'uuid'
import produce from 'immer'

import {Identifiable, IPagePayload} from '../../Interfaces'

import {useGraphql} from '../../hooks/graphql'
import {AsyncJsx} from '../../components/async-jsx'
import {AddIcon, BarsIcon, CloseIcon} from '../../atoms/icons'

import {LayoutElement} from './layout-element'
import {ContentElement, StaticHtmlView} from '../../components/ContentElement'
import {ElementFields, ElementSelection, Sidebar} from '../../components/sidebar'
import Views from '../../components/views'
import GlobalElements from '../../components/GlobalElements'
import useModal from '../../hooks/useModal'
import Modal from '../../components/modal'
import {PresetSelect} from '../../components/presets'

function Editor() {
  const {advMode, AdvModeToggle} = useContext(ModeContext)
  const {isShowing: selectPresetShowing, toggle: toggleSelectPreset} = useModal()
  const [view, setView] = useState<string>('EDIT')
  const [page, setPage] = useState<IPagePayload>({
    state: 'IDLE',
    resp: null,
    errors: [],
    selectedElement: null,
  })
  const {domain, slug} = useParams<Identifiable>()
  const {gMutation, gQuery, gPageQueryString} = useGraphql()
  const [sidebarView, setSidebarView] = useState<'FIELDS' | 'LIST'>('FIELDS')

  const keyUpHandler = useCallback(
    (e: any) => {
      if (e.altKey) {
        switch (e.key) {
          case '/':
            view === 'PREVIEW' ? setView('EDIT') : setView('PREVIEW')
            break
        }
      } else {
        switch (e.key) {
          case 'Escape':
            setPage(
              produce((draft: IPagePayload) => {
                draft.selectedElement = null
              }),
            )
            break
        }
      }
    },
    [view],
  )

  useEffect(() => {
    window.addEventListener('keyup', keyUpHandler)
    return () => {
      window.removeEventListener('keyup', keyUpHandler)
    }
  }, [keyUpHandler])

  const isLoading = page.state === 'LOADING'
  const isSuccess = page.state === 'FINALIZED'

  useEffect(() => {
    if (page.state === 'IDLE') {
      gQuery(
        `
          {
            page(domain: "${domain}", slug: "${slug}") {
              ${gPageQueryString}
            }
          }
        `,
        {setResp: setPage, queryName: 'page'},
      )
    }
  }, [gQuery, slug, domain, page, gPageQueryString])

  function SavePage(page: IPagePayload) {
    gMutation(
      `
        mutation updatePageElements {
          updatePageElements(
            input: {
              pageId: "${page.resp.id}",
              childElements: ${JSON.stringify(JSON.stringify(page.resp.childElements))},
              htmlContent: ${JSON.stringify(StaticHtmlView(page))}
            }
          ) {
            page {
              ${gPageQueryString}
            }
          }
        }
      `,
      {
        setResp: setPage,
        initData: page,
        mutationName: 'updatePageElements',
        attributeName: 'page',
      },
    )
  }
  return (
    <AsyncJsx isLoading={isLoading} isSuccess={isSuccess}>
      {page.resp ? (
        <>
          <div className="flex">
            <Link to={'/websites/' + domain}>
              <CloseIcon />
            </Link>
            <AdvModeToggle />
            <button
              className={`btn ${view === 'EDIT' ? 'btn' : 'btn-gray'}`}
              onClick={() => setView('EDIT')}
            >
              Edit
            </button>
            <button
              className={`btn ${view === 'PREVIEW' ? 'btn' : 'btn-gray'}`}
              onClick={() => setView('PREVIEW')}
            >
              Preview
            </button>
            <button
              className="btn"
              onClick={() => SavePage(page)}
              disabled={page.state !== 'modified' ? true : false}
            >
              Save Changes
            </button>
          </div>
          <div className="flex">
            <div className="mx-auto flex-1">
              <GlobalElements stylesheet={page.resp?.website?.globalStyles} />
              {/* {JSON.stringify(page.resp.childElements)} */}
              {page.resp.childElements?.length > 0 ? (
                page.resp.childElements.map(element => (
                  <Views
                    key={element.id}
                    views={[
                      [
                        'PREVIEW',
                        <ContentElement
                          ids={[element.id]}
                          element={element}
                          key={element.id}
                        />,
                      ],
                      [
                        'EDIT',
                        <LayoutElement
                          ids={[element.id]}
                          element={element}
                          key={element.id}
                          page={page}
                          setPage={setPage}
                        />,
                      ],
                    ]}
                    currentView={view}
                  />
                ))
              ) : (
                <div className="flex text-center">
                  <button
                    className="flex-1 border p-3 add-row-below"
                    onClick={() => {
                      const newId = uuidv5('zelda-windwaker', uuidv1())
                      setPage(
                        produce((draft: IPagePayload) => {
                          draft.state = 'modified'
                          if (draft.resp) {
                            draft.resp.childElements = [
                              {
                                id: newId,
                                ids: [newId],
                                parentIds: [],
                                name: '',
                                backgroundImage: '',
                                defaultImagePath:
                                  draft.resp?.website?.defaultImagePath || '',
                                position: 0,
                                enum: 'BLOCK',
                                isLink: false,
                                elementAttributes: {},
                                moduleAttributes: {},
                                childElements: [],
                                elementTag: 'TEXT',
                                moduleName: '',
                              },
                            ]
                          }
                        }),
                      )
                    }}
                  >
                    <AddIcon /> Add First Element
                  </button>
                </div>
              )}
            </div>

            {page.selectedElement && sidebarView === 'FIELDS' ? (
              <Sidebar setPage={setPage}>
                <div onClick={() => setSidebarView('LIST')} className="btn">LIST VIEW</div><br />
                <ElementFields
                  setPage={setPage}
                  page={page}
                  element={page.selectedElement}
                />
              </Sidebar>
            ) : page.selectedElement && sidebarView === 'LIST' ? (
              <Sidebar setPage={setPage}>
                <div onClick={() => setSidebarView('FIELDS')} className="btn">FIELD VIEW</div><br />
                <ElementSelection
                  setPage={setPage}
                  page={page}
                  element={page.selectedElement}
                />
              </Sidebar>
            ) : null}
          </div>
        </>
      ) : null}
    </AsyncJsx>
  )
}

export default Editor
