import React from 'react'
// import {ImageIcon} from '../atoms/icons'
import ImageSelect from '../components/image-select'
import Modal from '../components/modal'
import useModal from '../hooks/useModal'
import {IDynamicElement} from '../Interfaces'

function ImageField({
  element,
  setPage,
  handleElementChange,
  name,
  title,
  value,
  attr,
  attr2 = null
}: {
  element: IDynamicElement
  value: string
  setPage: (val: object) => void
  handleElementChange: (val: object) => void
  name: string
  title: string
  attr: string,
  attr2: string
}) {
  const {isShowing, toggle} = useModal()

  return (
    <>
      <label htmlFor={name}>{title}</label>
      {/* <span onClick={() => toggle()} className="pl-2">
        <ImageIcon />
      </span> */}
      <Modal isShowing={isShowing} hide={toggle}>
        <ImageSelect
          element={element}
          setPage={setPage}
          handleElementChange={handleElementChange}
          toggle={toggle}
          attr={attr}
          attr2={attr2}
        />
      </Modal>
      <input
        id={name}
        type="text"
        className="max-w-full input"
        value={value || ''}
        onChange={e => {
          handleElementChange({
            newValue: e.target.value,
            element: element,
            setPage: setPage,
            attr: attr,
            attr2: attr2,
          })
        }}
      />
    </>
  )
}

export {ImageField}
