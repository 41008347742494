import produce from 'immer'
import React, {useEffect, useState} from 'react'
import {AddIcon, DeleteIcon} from '../atoms/icons'
import DefaultImageHandler from '../components/DefaultImageHandler'
import {elementReducer} from '../components/element-handler'
import {IDynamicElement, INav, INavItem, IPage, IPagePayload} from '../Interfaces'
import {ImageField} from '../utils/fields'

function PethyNavigationControls({
  element,
  handleElementChange,
  setPage,
}: {
  element: IDynamicElement
  handleElementChange: (val: object) => void
  setPage: (val: object) => void
}) {
  const defaultMobileClassNames =
    'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'
  const defaultDesktopClassNames =
    'hover:bg-gray-100 rounded px-3 py-2 text-sm font-medium'
  return (
    <>
      <ImageField
        element={element}
        setPage={setPage}
        handleElementChange={handleElementChange}
        value={element.moduleAttributes?.logo || ''}
        title="Logo"
        name="moduleLogo"
        attr="moduleAttributes"
        attr2="logo"
      />

      <ul>
        {element.moduleAttributes?.items && element.moduleAttributes?.items.length > 0 ? (
          element.moduleAttributes.items.map((item: INavItem, i: number) => (
            <li key={i} className="border-l-2 border-black ml-2 pl-2 mt-8">
              <button
                onClick={() =>
                  setPage(
                    produce((draft: IPagePayload) => {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      el.moduleAttributes.items.splice(i, 1)
                      draft.selectedElement = el
                    }),
                  )
                }
                className="absolute right-3"
              >
                <DeleteIcon />
              </button>
              <label htmlFor={item.title + '-href-' + i}>Link</label>
              <input
                id={item.title + '-href-' + i}
                type="text"
                className="max-w-full input"
                value={item.href || ''}
                onChange={event => {
                  event.persist()
                  setPage(
                    produce((draft: IPagePayload) => {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      el.moduleAttributes.items[i].href = event.target.value
                      draft.selectedElement = el
                    }),
                  )
                }}
              />
              <label htmlFor={item.title + '-title-' + i}>Title</label>
              <input
                id={item.title + '-title-' + i}
                type="text"
                className="max-w-full input"
                value={item.title || ''}
                onChange={event => {
                  event.persist()
                  setPage(
                    produce((draft: IPagePayload) => {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      el.moduleAttributes.items[i].title = event.target.value
                      draft.selectedElement = el
                    }),
                  )
                }}
              />
              <label htmlFor={item.desktopClassNames + '-desktopClassNames-' + i}>
                Desktop Classes
              </label>
              <input
                id={item.desktopClassNames + '-desktopClassNames-' + i}
                type="text"
                className="max-w-full input"
                value={item.desktopClassNames || ''}
                onChange={event => {
                  event.persist()
                  setPage(
                    produce((draft: IPagePayload) => {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      el.moduleAttributes.items[i].desktopClassNames = event.target.value
                      draft.selectedElement = el
                    }),
                  )
                }}
              />
              <label htmlFor={item.mobileClassNames + '-mobileClassNames-' + i}>
                Mobile Classes
              </label>
              <input
                id={item.mobileClassNames + '-mobileClassNames-' + i}
                type="text"
                className="max-w-full input"
                value={item.mobileClassNames || ''}
                onChange={event => {
                  event.persist()
                  setPage(
                    produce((draft: IPagePayload) => {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      el.moduleAttributes.items[i].mobileClassNames = event.target.value
                      draft.selectedElement = el
                    }),
                  )
                }}
              />
              <button
                onClick={() => {
                  setPage(
                    produce((draft: IPagePayload) => {
                      draft.state = 'modified'
                      const el = elementReducer(draft.resp, {
                        ids: element.ids,
                      })
                      el.moduleAttributes.items.splice(i + 1, 0, {
                        title: '',
                        href: '',
                        desktopClassNames: defaultDesktopClassNames,
                        mobileClassNames: defaultMobileClassNames,
                      })
                      draft.selectedElement = el
                    }),
                  )
                }}
                className="absolute right-3 text-sm border-2 border-pethyGreen text-pethyGreen rounded-full leading-3"
              >
                <AddIcon />
              </button>
            </li>
          ))
        ) : (
          <button
            className="px-1 text-pethyGreen btn"
            title="Add Child Element"
            onClick={() => {
              setPage(
                produce((draft: IPagePayload) => {
                  draft.state = 'modified'
                  const el = elementReducer(draft.resp, {
                    ids: element.ids,
                  })

                  el.moduleAttributes = el.moduleAttributes || {}
                  el.moduleAttributes.items = el.moduleAttributes?.items
                    ? el.moduleAttributes.items
                    : []

                  const items = el.moduleAttributes.items
                    ? el.moduleAttributes.items
                    : ([] as INavItem[])

                  el.moduleAttributes.items = [
                    ...items,
                    {
                      title: '',
                      href: '',
                      desktopClassNames:
                        'hover:bg-gray-100 rounded px-3 py-2 text-sm font-medium',
                      mobileClassNames:
                        'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium',
                    },
                  ]
                  draft.selectedElement = el
                }),
              )
            }}
          >
            Add Nav Item
          </button>
        )}
      </ul>
    </>
  )
}

function PethyNavigation({
  moduleElement,
  children,
}: {
  moduleElement: INav
  children?: any
}) {
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [showAccountNav, setShowAccountNav] = useState(false)
  const [useAccountNav] = useState(false)

  return (
    <nav className="border-b-1 w-full">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <a href="/" className="flex-1">
                {moduleElement.moduleAttributes?.logo ? (
                  <img
                    className="block h-14 w-auto"
                    id="logo"
                    src={DefaultImageHandler(
                      moduleElement.moduleAttributes?.logo,
                      moduleElement?.defaultImagePath || '',
                    )}
                    alt={moduleElement.moduleAttributes?.logoAlt}
                  />
                ) : null}
              </a>
            </div>
            <div className="hidden sm:block sm:ml-6 flex-1">
              <div className="flex space-x-4 justify-end">
                {moduleElement.moduleAttributes?.items
                  ? moduleElement.moduleAttributes?.items.map(
                      (item: INavItem, i: number) => (
                        <a
                          key={i}
                          href={item.href}
                          className={item.desktopClassNames}
                          aria-current="page"
                        >
                          {item.title}
                        </a>
                      ),
                    )
                  : null}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setShowMobileNav(!showMobileNav)}
            >
              <span className="sr-only">Open main menu</span>
              {showMobileNav ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>

          {useAccountNav ? (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {/* <!-- Profile --> */}
              <div className="ml-3 relative">
                <div>
                  <button
                    type="button"
                    className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                    onClick={() => setShowAccountNav(!showAccountNav)}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src="" alt="" />
                  </button>
                </div>
                {/* <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
                <div
                  className={`${
                    showAccountNav
                      ? 'transform opacity-100 scale-100 transition ease-out duration-100'
                      : 'transform opacity-0 scale-95 transition ease-in duration-75'
                  } origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  {/* <!-- Active "bg-gray-100", Not Active: "" --> */}
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-0"
                  >
                    Your Profile
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-1"
                  >
                    Settings
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-2"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className={`${showMobileNav ? '' : 'hidden'} sm:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          {/* active: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
          {moduleElement.moduleAttributes?.items
            ? moduleElement.moduleAttributes?.items.map((item, i) => (
                <a
                  key={i}
                  href={item.href}
                  className={item.mobileClassNames}
                  aria-current="page"
                >
                  {item.title}
                </a>
              ))
            : null}
        </div>
      </div>
    </nav>
  )
}

export {PethyNavigation, PethyNavigationControls}
