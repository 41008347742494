const pethyClasses = {
  columnDefaults: 'flex-1',
  rowDefaults: 'flex flex-wrap',
  containerDefaults: '',
  imageDefaults: 'max-w-full h-auto',
  elementDefaults: 'flex flex-1',
  elementEditorDefaults: 'p-5 border border-black pethy-admin',
}
const defaults = {
  image: 'https://s3.amazonaws.com/pethy.app/images/pethy-logo.png',
}
function highlightDestroy(id: string) {
  document.getElementById(id).classList.add('highlight-destroy')
}
function removeHighlightDestroy(id: string) {
  document.getElementById(id).classList.remove('highlight-destroy')
}
// const defaultFontStyles = [
//   {name: 'header-one', classes: 'text-6xl'},
//   {name: 'header-two', classes: 'text-5xl'},
//   {name: 'header-three', classes: 'text-4xl'},
//   {name: 'header-four', classes: 'text-3xl'},
//   {name: 'header-five', classes: 'text-2xl'},
//   {name: 'paragraph', classes: 'text-base'},
//   {name: 'unstyled', classes: 'text-base'},
//   {name: 'unordered-list-item', classes: 'text-base'},
//   {name: 'ordered-list-item', classes: 'text-base'},
//   {name: 'blockquote', classes: 'text-base'},
//   {name: 'link', classes: 'text-pethyGreen'},
//   {name: 'code-block', classes: 'text-base font-mono bg-gray-200 p-3 my-2'},
// ]

const defaultFontStyles = {
  // placeholder: 'editor-placeholder',
  paragraph: 'text-base',
  quote: 'text-base',
  heading: {
    h1: 'text-6xl',
    h2: 'text-5xl',
    h3: 'text-4xl',
    h4: 'text-3xl',
    h5: 'text-2xl',
    h6: 'text-base',
  },
  list: {
    nested: {
      listitem: 'text-base',
    },
    ol: '',
    ul: '',
    listitem: '',
  },
  image: 'max-w-full',
  link: 'text-pethyGreen',
  text: {
    bold: 'font-bold',
    italic: 'italic',
    overflowed: '',
    hashtag: '',
    underline: 'underline',
    strikethrough: 'line-through',
    underlineStrikethrough: 'line-through underline',
    code: 'text-base font-mono bg-gray-200',
  },
  code: 'text-base font-mono bg-gray-200 p-3 my-2',
  // codeHighlight: {
  //   atrule: 'editor-tokenAttr',
  //   attr: 'editor-tokenAttr',
  //   boolean: 'editor-tokenProperty',
  //   builtin: 'editor-tokenSelector',
  //   cdata: 'editor-tokenComment',
  //   char: 'editor-tokenSelector',
  //   class: 'editor-tokenFunction',
  //   'class-name': 'editor-tokenFunction',
  //   comment: 'editor-tokenComment',
  //   constant: 'editor-tokenProperty',
  //   deleted: 'editor-tokenProperty',
  //   doctype: 'editor-tokenComment',
  //   entity: 'editor-tokenOperator',
  //   function: 'editor-tokenFunction',
  //   important: 'editor-tokenVariable',
  //   inserted: 'editor-tokenSelector',
  //   keyword: 'editor-tokenAttr',
  //   namespace: 'editor-tokenVariable',
  //   number: 'editor-tokenProperty',
  //   operator: 'editor-tokenOperator',
  //   prolog: 'editor-tokenComment',
  //   property: 'editor-tokenProperty',
  //   punctuation: 'editor-tokenPunctuation',
  //   regex: 'editor-tokenVariable',
  //   selector: 'editor-tokenSelector',
  //   string: 'editor-tokenSelector',
  //   symbol: 'editor-tokenProperty',
  //   tag: 'editor-tokenProperty',
  //   url: 'editor-tokenOperator',
  //   variable: 'editor-tokenVariable',
  // },
}
export {
  defaults,
  pethyClasses,
  highlightDestroy,
  removeHighlightDestroy,
  defaultFontStyles,
}
