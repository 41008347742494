import React from 'react'

function LargeNumberedList({
  children,
}: {
  children: any
}) {
  return children.map((child: any, index: number) => (
    <div className="flex my-4" key={index}>
      <div className="flex-none p-4">
        <span className="relative text-5xl font-bold -top-6">{index + 1}.</span>
      </div>
      <div className="flex-1 px-4">{child}</div>
    </div>
  ))
}
export default LargeNumberedList
