import React, {createContext, useEffect, useState} from 'react'
import {GraphQLClient} from 'graphql-request'

const FetchContext = createContext<any>({})
const {Provider} = FetchContext

function FetchProvider({children}: {children: React.ReactNode}) {
  const [graphQLClient, setGraphQLClient] = useState<any>(
    null,
  )

  useEffect(() => {
    // if (process.env) var env = process.env
    if (graphQLClient === null) {
      const endpoint = `${process.env.REACT_APP_PETHY_API_URL}/v1`
      const client = new GraphQLClient(endpoint, {})
      setGraphQLClient(client)
    }
  }, [graphQLClient])

  return (
    <Provider
      value={{
        graphQLClient,
      }}
    >
      {children}
    </Provider>
  )
}

export {FetchContext, FetchProvider}
